:root {
  --greyed-section-color: #f9fafb;
}

.section {
  padding: 30px 60px;
  font-size: var(--font-size-base);
  line-height: var(--font-line-base);
  color: var(--primary-color);
  flex-direction: column;
}

.section .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.section-title {
  font-size: 1.75rem;
  line-height: 2.5rem;
}

.section-hero-title {
  font-size: 2.15rem;
  line-height: 2.8rem;
}

/* -- HERO SECTION -- */
.hero {
  min-height: 460px;
  align-items: flex-start;
}

.hero-right-background {
  margin-left: auto;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f2f4f7;
}

.hero-content {
  z-index: 1;
  flex: 1;
  margin-left: 15px;
  margin-right: 15px;
  max-width: 1000px;
}

.hero-content-image {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 55vw;
  max-width: 850px;
}

.hero-description {
  text-align: center;
}

.hero-subtitle {
  max-width: 477px;
  margin-top: 50px;
  margin-bottom: 15px;
  z-index: 9;
}

.hero-or-text {
  font-size: 25px;
  display: inline;
  margin: 15px 0;
}

.hero-register-box {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.hero-register-box.row {
  margin-right: -10px;
  margin-left: -10px;
}

.hero-register-box div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.hero-register-box .auth-button {
  padding: 24px !important;
}

.hero-left,
.hero-center,
.hero-right {
  text-align: center;
}

.how-it-works {
  background: var(--white-color);
  padding: 50px 0;
}

.how-it-works-title {
  font-size: 30px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.37;
  letter-spacing: normal;
  text-align: center;
  color: #252422;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.how-it-works-subtitle {
  text-align: center;
  margin-top: 25px;
  color: #000;
  font-weight: bold;
  font-size: 1em;
}

.how-it-works-subtitle-number {
  color: #aaa;
  margin-right: 5px;
}

.find-best-vehicles__title {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 30px;
}

.find-best-vehicles__logo-thumnbnail {
  max-width: calc(10% - 20px);
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white-color);
  margin: 15px 10px 0 10px;
  padding: 0 10px 2px;
  cursor: pointer;
  position: relative;
}

@media (max-width: 576px) {
  .section {
    padding: 1em !important;
  }

  .buy-card.bordered {
    border-right: 0;
    border-bottom: 1px solid #d0d5dd;
  }

  .hero-right-background {
    display: none;
  }

  .hero-content-image {
    display: none;
  }
}

@media (max-width: 768px) {
  .sell-card-wrapper,
  .buy-card-wrapper {
    margin: 0 !important;
  }

  .live-online-auction-content-left {
    padding: 0 !important;
    margin-bottom: 30px;
  }

  .live-online-auction-content-right {
    padding: 0 !important;
  }

  .live-online-auction__image {
    min-width: 100% !important;
  }
}

@media (max-width: 991px) {
  .find-best-vehicles__wrapper {
    /*justify-content: space-between;*/
    justify-content: center;
    align-content: center;
  }

  .find-best-vehicles__logo-thumnbnail {
    max-width: calc(33.3% - 40px);
  }

  .buy-card-wrapper.bordered {
    border-right: 0 !important;
    border-bottom: 1px solid #d0d5dd !important;
  }

  .buy-content__card-list-container {
    padding: 0 32px 0 32px !important;
  }

  .buy-card-wrapper {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .mobile-section .footer-nav__download-app-wrapper {
    flex-direction: column !important;
  }

  .mobile-section .footer-nav__download-app-wrapper:nth-child(1) {
    margin-top: 30px;
  }
}

.find-best-vehicles {
  background: var(--secondary-color);
  padding: 50px 0;
}

.find-best-vehicles__search {
  margin-bottom: 5px;
}

#find-best-vehicles__search {
  margin-top: 10px;
}

.find-best-vehicle__make-name {
  font-weight: bolder;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 5px;
}

.find-best-vehicles__logo-wrapper {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}
.find-best-vehicles__logo-wrapper_active {
  border: 1px solid var(--primary-color);
}

.find-best-vehicles__logo {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.find-best-vehicles__wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.find-best-vehicles__count {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -15px;
  right: -15px;
  padding: 6px 8px;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #174e86;
  color: #ffffff;
  font-size: 14px;
  font-weight: 800;
}

.find-best-vehicle__or {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 18px;
  margin: 20px 0;
}

.latest-vehicles {
  background: var(--disabled-color);
  padding: 50px 0;
}

.latest-vehicles__more-button {
  margin: 50px 0 10px 0;
}

.home-my-vehicles {
  background-color: var(--disabled-color);
  padding-bottom: 50px;
}

.home-my-vehicles__title {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 30px;
  margin-top: 40px;
}

.home-my-vehicles__button-wrapper {
  margin-top: 40px;
}

.home-my-bids {
  background-color: var(--secondary-color);
  padding-bottom: 50px;
}

.home-my-bids__title {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 30px;
  margin-top: 40px;
}

.home-my-bids__button-wrapper {
  margin-top: 40px;
}

.underwrite {
  background: #fff;
  padding-top: 40px;
  padding-bottom: 10px;
}

.hero-title {
  position: relative;
  z-index: 9;
}

/* -- MOBILE -- */

.section.mobile-section {
  min-height: 290px;
  background: var(--greyed-section-color);
}

.mobile-section .footer-nav__download-app-wrapper {
  width: 100%;
  height: auto;
  justify-content: center;
  flex-direction: row;
  gap: 60px;
}

.mobile-section .footer-nav__download-app-wrapper img {
  max-width: 300px;
}

/* -- RECENTLY SOLD -- */

.recently-sold-carousel__item {
  width: 270px;
  height: 385px;
  border-bottom: 1px solid var(--primary-color);
}

.recently-sold-carousel__item-image {
  height: 160px;
}

.recently-sold-carousel__item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.recently-sold-carousel .slick-slide {
  margin: 0 20px;
}

.recently-sold-carousel__item-content {
  padding: 24px 0;
}

.recently-sold-carousel__item-content .item-content__title {
  font-size: 1.75rem;
  line-height: 2rem;
}

.recently-sold-carousel__buttons {
  display: flex;
  height: 100%;
  align-items: flex-end;
}

.recently-sold-carousel__item-content .item-content__sold-box {
  background-color: #caefe9;
  color: #00957b;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 92px 10px 92px;
}

.carousel-button-left {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white !important;
  border-radius: 0;
  border: 1px solid var(--primary-color);
  width: 45px;
  height: 45px;
  margin: 0;
  padding: 13px !important;
}

.carousel-button-right {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  width: 45px;
  height: 45px;
  border-radius: 0;
  padding: 13px !important;
}

.carousel-button-right:hover {
  background-color: var(--primary600);
}

.carousel-button-right:active {
  background-color: var(--primary500);
}

.carousel-button-left:hover {
  border: 1px solid var(--primary600);
}

.carousel-button-left:hover img {
  filter: invert(52%) sepia(38%) saturate(570%) hue-rotate(179deg) brightness(94%)
    contrast(96%);
}

.carousel-button-left:active {
  border: 1px solid var(--primary500);
}

/* -- AUCTION SECTION -- */

.auction-section {
  padding: 0 !important;
  width: 100%;
}

.auction-section > div {
  flex: 1;
}

/* -- CONTACT US -- */

section.contact-us-section {
  padding: 0;
}

.contact-us-section .contact-us {
  width: 100%;
}

/* -- LIVE ONLINE SECTION -- */

.live-online-auction {
  background: var(--white-color);
}

.live-online-auction-content {
  flex: 1;
}

.live-online-auction-content-left .common-button {
  padding: 13px 50px 13px 50px !important;
}

.live-online-auction__image {
  min-width: 400px;
}

.line-online-auction__background-right {
  width: 40%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--primary-color);
}

/* -- BUY/SELL SECTIONS -- */

.buy {
  background: var(--white-color);
  padding: 40px 60px;
}

.sell {
  background: var(--greyed-section-color);
  padding: 40px 60px;
}

.sell-content,
.buy-content {
  flex: 1;
}

.buy-content__card-list-container {
  border: 1px solid #e4e7ec;
  padding-top: 32px;
  padding-bottom: 32px;
}

.buy-content__title-container {
  /*min-height: 144px;*/
}

.sell-card-wrapper {
  padding-top: 32px;
  padding-bottom: 32px;
}

.buy-card-wrapper {
  padding-left: 32px;
  padding-right: 32px;
}

.buy-card-wrapper.bordered {
  border-right: 1px solid #d0d5dd;
}

.sell-card-wrapper.bordered {
  border-bottom: 1px solid #d0d5dd;
}

.sell-card__image,
.buy-card__image {
  box-shadow: 0 4px 6px 0 #66708540;
  background: linear-gradient(0deg, #bacce9, #bacce9), linear-gradient(0deg, #ffffff, #ffffff);
  border: 2px solid var(--white-color);
  padding: 16px;
  width: 72px;
  height: 72px;
}

.buy-card__title {
  margin-top: 32px;
  margin-bottom: 16px;
}
.buy-card__description {
  font-size: 16px;
}

.buy-card__image img {
  width: 40px;
}

.underwrite h3,
.buy h3 {
  color: #252422;
  font-weight: 800;
  font-size: 26px;
  text-align: center;
}

.underwrite h3 {
  margin-bottom: 30px;
}

.underwrite p,
.buy p {
  color: #252422;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
}

.underwrite p.bulletpoints,
.buy p.bulletpoints {
  text-align: left;
  padding-left: 40px;
  position: relative;
  font-size: 16px;
  line-height: 22px;
}

.underwrite p.bulletpoints i,
.buy p.bulletpoints i {
  color: #174e86;
  font-size: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: 0;
}

.underwrite img,
.buy img {
  display: block;
  max-width: 100%;
  margin: auto;
}

.underwrite img.img-example {
  margin-bottom: 30px;
}

.buy-image {
  padding-top: 30px;
  padding-bottom: 50px;
}
