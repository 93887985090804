@media screen and (max-height: 950px) {
  .add-tyres-form {
    margin-top: 0 !important;
    margin-bottom: 50px !important;
  }
}

.add-vehicle-content-wrapper {
  background-color: var(--white);
  padding: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Add number form */
.add-number-form__input-field {
  max-width: 450px;
}

.add-number-form__search-button {
  width: 200px !important;
  height: 44px !important;
}

.add-number-form__input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.add-vehicle__title {
  font-size: x-large;
}

/* Vehicle not found form */
.vehicle-not-found__button {
  width: 220px;
}

.confirm-number__title {
  font-size: x-large;
}

.confirm-number__input {
  width: 80%;
  max-width: 315px;
  margin: 10px;
}

.confirm-number__input:disabled {
  background-color: #ffffff;
}

.confirm-number__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 250px;
  margin: 10px;
}

.confirm-number__subtitle {
  font-size: 16px;
}

.confirm-number__number-field {
  background-color: var(--primary-color);
  border-radius: 3px;
  width: 80%;
  max-width: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
  padding: 20px;
}
.confirm-number__question {
  color: var(--gray80);
  text-align: center;
  font-size: large;
  font-weight: bolder;
}

.confirm-number__title {
  color: var(--gray80);
  text-align: left;
  align-self: start;
  font-size: large;
  font-weight: bolder;
}

.confirm-number__car-number {
  color: var(--white-color);
  font-size: x-large;
  font-weight: bolder;
}

.confrim-number__button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.confirm_number__buton-container--back {
  display: flex;
  justify-content: flex-start;
}

.confirm_number__button {
  max-width: 142px;
  margin: 10px 15px 0 15px;
}

.basic-data__title {
  font-size: x-large;
  text-align: center;
}

.basic-data__button {
  margin-top: 10px;
}

.added-photo {
  width: 100%;
  height: auto;
}

.added-photo-container {
  width: 100%;
  height: 100%;
  max-width: 504px;
}

.add-photo__add_center-icon {
  width: 40px;
  height: 40px;
  background-color: var(--green600);
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-photo__drop-text {
  margin-bottom: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray400);
  font-weight: bold;
  font-size: 0.625rem; /* 10px */
  line-height: 0.75rem;
}

.add-photo__base-style {
  height: 280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  border-width: 1px;
  border-style: dashed;
  /*border-color: var(--gray300);*/
  background-color: var(--white-color);
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
}

.add-photo__summary-button {
  max-width: 480px;
}

.add-photo__add-damage-style {
  height: 180px !important;
}

.add-photo__base-style--disabled {
  cursor: default;
}

.add-photo__active-style {
  border-color: #2196f3;
}

.add-photo__accept-style {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.add-photo__reject-style {
  border-color: #ff1744;
}

.add-more-pictures__icon {
  margin-top: 5px;
}

@media (max-width: 400px) {
  .confirm-number__question {
    font-size: small;
  }
  .confirm-number__car-number {
    font-size: medium;
  }
}

.add-details__label {
  font-size: 14px;
  font-weight: 700;
  border-style: solid;
  border-width: 1px;
  padding: 2px 8px;
  height: 30px;
}

.add-details__label-true {
  color: var(--white-color);
  border-color: var(--primary700);
  background-color: var(--primary700);
}

.add-details__label-false {
  color: var(--primary700);
  border-color: var(--primary700);
  background-color: var(--white-color);
}

.add-details__label--loaded {
  cursor: pointer;
}

.add-details__label--not-loaded {
  cursor: default;
}

.add-details__label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
}

.noselect {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.damages__container {
  display: flex;
  background-color: white;
  border-radius: 6px;
}

.damages__preview {
  flex: 1;
  width: 100%;
}

.damages__title {
  color: var(--primary600);
  font-weight: bold;
}

.damages__image {
  position: relative;
  height: 180px;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-self: center;
}

.damages__select-area {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.damages__select {
  width: 100%;
}

.damages__number {
  text-align: left;
  margin-top: 10px;
  font-weight: bold;
}

.add-description__button {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

#add-description__user-description {
  min-height: 200px;
}
.service-history__title {
  font-size: x-large;
  font-weight: 800;
  text-align: center;
  color: var(--black100);
}

.featured-specification__label {
  font-weight: 800;
  text-align: center;
  color: var(--black100);
  display: block;
}

.service-history__manual-record:nth-child(n + 2)::before {
  content: '';
  transform: translateX(2.5%);
  width: 95%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.service-history__images-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  margin: 15px 0;
}

.service-history__add-image {
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-history__add-image-ico {
  color: var(--primary-color);
  font-size: 24px;
  border: 2px solid var(--primary-color);
  padding: 8px;
  border-radius: 25px;
}

.service-history__delete-image {
  visibility: hidden;
  position: absolute;
  margin-left: -130px;
  margin-top: 5%;
}

.service-history__delete-image-ico {
  background-color: rgba(255, 255, 255, 0.6);
  color: var(--red-color) !important;
  padding: 10px;
  border-radius: 25px;
}

.service-history__image:hover .service-history__delete-image {
  visibility: visible;
}

.service-history_trash-button {
  width: 25px;
  height: 25px;
  cursor: pointer;
  z-index: 1;
  outline: none !important;
  margin-bottom: -20px;
}

.add-details__input-wrapper {
  position: relative;
}

.add-details__input {
  height: 50px;
}

.add-details__button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  min-width: 60px !important;
  width: 60px !important;
  height: 32px;
  background-color: var(--primary-color);
  border-radius: 20px;
  font-size: 15px;
  color: var(--white-color);
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  outline: none !important;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-details__button-icon {
  font-size: 15px;
  color: var(--white-color);
}

.summary__slide {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.summary__slide-thumbnail {
  object-fit: cover;
}

.summary__slide-no-image {
  /*max-height: 850px !important;*/
}

@media screen and (max-width: 1500px) {
  .summary__slide-list {
    object-fit: cover !important;
  }
}

.summary__slide-button {
  width: 100%;
  height: 100%;
}

.summary__slide-active {
}

.summary__slide-inactive {
  opacity: 0.7;
}

.summary__slider-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: black;
}

.summary__slider {
  height: 450px;
  position: relative;
  flex-basis: 100%;
}

@media (min-width: 992px) {
  .summary__slider {
    flex-basis: 75%;
  }

  .summary__thumbnails-slider {
    flex: 1;
  }
}

@media screen and (max-width: 991px) {
  .summary__thumbnails-slider {
    display: none !important;
  }
}

.summary__slider .summary__slide-button {
  background-color: var(--black-color);
}

.summary__slider .summary__slide-button .summary__slide {
  background-color: var(--black-color);
}

.summary__thumbnails-slider .summary__slide-button {
  background-color: var(--black-color);
}

.summary__thumbnails-slider .summary__slide-button .summary__slide {
  height: 100%;
  background-color: var(--black-color);
}

.summary__thumbnails-slider {
  width: 100%;
  height: 450px;
}

.summary__thumbnails-slider-1 {
  padding-right: 80%;
}

.summary__thumbnails-slider-2 {
  padding-right: 60%;
}

.summary__thumbnails-slider-3 {
  padding-right: 40%;
}

.summary__thumbnails-slider-4 {
  padding-right: 20%;
}

.summary__slider-dots-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0;
}

.summary__slider-dot {
  width: 10px;
  height: 10px;
  background-color: transparent;
  border: 1px solid #cacaca;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
  outline: none !important;
}

.summary__slider-dot--active {
  border: 1px solid var(--black-color);
  background-color: var(--white-color);
}

.summary__slider-arrow-buttons-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 0;
  padding: 0 15px;
  transform: translateY(-50%);
}

.summary__slider-arrow-button {
  position: absolute;
  top: 50%;
  padding: 0 15px;
  transform: translateY(-50%);
  outline: none !important;
  font-size: 40px;
  color: #cacaca;
}

.summary__slider-arrow-button--left {
  left: 0;
}
.summary__slider-arrow-button--right {
  right: 0;
}

.summary__photo-counter-wrapper {
  position: absolute;
  bottom: 8px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.summary__photo-counter {
  margin: 2px 0 0 10px;
}

.main-description-container__type-label {
  background-color: #2f5188;
  padding: 2px 8px 2px 8px;
  color: white;
  font-size: 0.9rem;
}
.main-description-container__code {
  background-color: #d0d5dd;
  padding: 2px 8px 2px 8px;
  color: #667085;
}

.main-description-container__info-bar {
  background-color: white;
  border: 1px solid #d0d5dd;
  padding: 20px 40px 20px 40px;
}

.main-description-container__section-title {
  border-bottom: 1px solid black;
  color: var(--black100);
  font-size: 20px;
  font-weight: bold;
}

.auction-details-section {
  padding-top: 20px;
  background-color: transparent;
}

.auction-details-wrapper {
  padding-left: 40px;
}

@media (max-width: 767px) {
  .summary__action-time-and-price-wrapper {
    order: -1;
  }
}

.summary__list-item span {
  color: rgba(37, 36, 34, 0.7);
  display: block;
}
.summary__list-item b {
  display: block;
}

.summary__list-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0 15px;
}

.color-black {
  color: #2c2c2c !important;
}

.summary__list-item p {
  overflow-wrap: break-word;
}

.summary__feature-list-item-icon {
  margin-right: 10px;
}

.summary__feature-list-item span {
  color: rgba(37, 36, 34, 0.7);
  display: block;
}
.summary__feature-list-item b {
  display: block;
}

.summary__auction-time {
  color: var(--primary-color) !important;
}

.summary__auction-price-paragraph {
  font-size: var(--font-size-xxl);
  margin-bottom: 0;
}

.summary__auction-price {
  font-weight: bold;
  color: var(--primary-color);
}

.summary__auction-due-date-row {
  min-height: 31px;
  background-color: var(--primary-color);
}

.summary__auction-due-date {
  width: 100%;
  padding: 5px 0;
  text-align: center;

  color: var(--white-color);
}

.summary__slider_image_container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--black-color);
}

.summary__slider_image_container .summary__slider_image_container__player {
  padding-top: 0 !important;
  height: 100% !important;
  display: flex;
  align-items: center;
}

.summary__slider_image_container .video-react-video,
.popup__slider-wrapper .video-react-video {
  width: 100%;
  height: 100% !important;
}

.popup__slider-wrapper .video-react-video {
  background-color: var(--black-color);
}

.tertiary-button {
  width: 300px !important;
  height: 25px !important;
  font-size: 11px;
  text-transform: capitalize !important;
  color: var(--white-color);
  border-radius: 5px !important;
  cursor: initial !important;
  line-height: 1;
}

.draft-button {
  background-color: var(--gray100) !important;
}

.summary__car-features {
  display: flex;
  padding-top: 20px;
  background-color: transparent;
}

.summary__car-features_not_specified {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 20px 30px 0 30px;
  background-color: var(--secondary-color);
}

.summary__feature-pills {
  padding: 4px 8px;
  color: var(--white-color);
  background-color: #2f5188;
  font-size: 14px;
  font-weight: bold;
  margin-right: 8px;
  margin-bottom: 8px;
}

.summary__feature-pills-name {
  color: var(--white-color);
}

.summary__description {
  white-space: break-spaces;
}

.summary__section-title {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
}

.summary__services-and-tyres-wrapper {
}

.summary__service-list-item:last-child {
  border-bottom: 0 !important;
}

.summary__service-list-item {
  border-bottom: 1px solid #e2e2e2;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.summary__service-history-list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}

.summary__service-history-list-item {
  position: relative;
  cursor: pointer;
  height: 160px;
  overflow: hidden;
}

.summary__service-history-list-item:nth-child(1) {
  padding-left: 0;
}

.summary__service-history-list-item:nth-child(4n) {
  padding-right: 0;
}

.summary__service-history-list-item:nth-child(5n) {
  padding-left: 0;
}

.summary__service-history-list-item {
  padding-left: 5px;
  padding-right: 5px;
}

.summary__service-history-list-item-photo {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.summary__service-history-list-item-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--white-color);
}

.photo-summary__photos-wrapper,
.photo-summary__videos-wrapper {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 10px;
}

.photo-summary__photos-wrapper div img,
.photo-summary__videos-wrapper div img {
  border-radius: 0;
}

.photo-summary__add-video-title {
  color: var(--primary600);
}

.photo-summary__drag-drop-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
}

.photo-summary__service-history-photo-wrapper,
.photo-summary__photo-wrapper {
  position: relative;
  overflow: hidden;
  height: 220px;
  padding: 20px;
  margin: 0;
}

.service-history-photo-wrapper__image-container {
  background-color: var(--white-color);
  max-height: 165px;
}

.photo-summary__video-wrapper {
  position: relative;
  overflow: hidden;
  height: 260px;
  padding: 20px 20px 40px 20px;
  margin: 0;
}

@media (max-width: 767px) {
  .photo-summary__photos-wrapper,
  .photo-summary__videos-wrapper {
    flex-direction: column;
  }
}

.no-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.photo-summary__photo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.photo-summary__video {
  width: 100%;
  height: 100%;
}

.photo-summary__service-history {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.photo-summary__title {
  color: var(--primary600);
  font-weight: bold;
}

.photo-summary__drag-position {
  position: absolute;
  right: 4px;
  bottom: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  color: white;
  padding: 3px;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  justify-content: center;
}

.photo-summary__photo-icon-button-container {
  position: absolute;
  bottom: 4px;
  left: 4px;
  display: flex;
  gap: 4px;
}

.photo-summary__photo-icon-button-container .photo-summary__photo-icon-button {
  width: 28px;
  height: 28px;
}

.photo-summary__photo-icon-button {
  z-index: 10 !important;
}

.photo-summary__photo-icon-button img {
  z-index: 10 !important;
}

.photo-summary__video-play-icon {
  font-size: 30px;
  color: var(--white-color);
}

.photo-summary__video-play-icon-button {
  outline: none !important;
}

.photo-summary__photo-icon {
  font-size: 15px;
  color: var(--primary-color);
}

.photo-summary__add-photo-button {
  flex-basis: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white-color);
  outline: none !important;
  height: 115px;
  position: relative;
  width: calc(100% - 8px);
  margin: 4px;
}
@media (max-width: 767px) {
  .photo-summary__add-photo-button {
    height: 150px;
  }
}

@media screen and (max-width: 992px) {
  .summary__service-history-list-item {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media screen and (max-width: 576px) {
  .add-photo__summary-button {
    max-width: 100% !important;
  }
}

.photo-summary__add-obligatory-video-button {
  height: 100% !important;
}

.photo-summary__add-obligatory-service-history-button {
  height: 165px !important;
}

.photo-summary__add-obligatory-photo-button,
.photo-summary__add-additional-photo-button {
  height: 180px !important;
}

.photo-summary__add-obligatory-photo-wrapper {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-summary__add-additional-photo-button .add-photo__add_center-icon.photo-place-grayed {
  background-color: var(--gray300) !important;
}

.photo-summary__photo-damages-icon {
  position: absolute;
  left: 4px;
  top: 4px;
}

.photo-summary__add-photo-upload-label,
.photo-summary__add-video-upload-label {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  cursor: pointer;
  display: flex;
  position: absolute;
}

.photo-summary__add-photo-upload-label--loading {
  animation: fileLoading 2s ease-in-out infinite;
}

.confirm-launch-form__header {
}

@keyframes fileLoading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.vehicle-details-nav-item::before {
  content: none !important;
}

.add-vehicle-header,
.add-vehicle-footer,
.auction-detail-header {
  margin-left: 0;
  margin-right: 0;
}

.add-vehicle-header .add-vehicle-header__title {
  font-size: 1.8rem;
}

.add-vehicle-header__buttons-container {
  gap: 20px;
  justify-content: end;
}

.add-vehicle-footer__buttons-container {
  justify-content: space-between;
}

.auction-detail-header button {
  width: 160px;
  padding: 10px;
}

.add-vehicle-header .common-button {
  width: 120px;
}

.add-vehicle-header__save-draft-button {
  width: 170px !important;
}

.auction-detail-header .form-group {
  width: 240px;
}

.auction-detail-header__actions-container {
  gap: 20px;
}

.add-vehicle__nav-item {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.add-vehicle__nav-item:hover {
  cursor: pointer !important;
}

.add-vehicle__nav-prev-item {
  opacity: 1 !important;
  font-weight: bold !important;
}

.add-vehicle__nav-prev-item:hover:before,
.add-vehicle__nav-prev-item:before {
  opacity: 0 !important;
}

.add-vehicle__nav-prev-item:hover {
}

.add-tyres-form {
  margin-top: 40px;
  padding-top: 40px;
  position: relative;
}

.add-tyres-form__top-row {
  position: relative;
  z-index: 3 !important;
}

.add-tyres-form__lower-row {
  position: relative;
  z-index: 2 !important;
  margin-top: 100px;
}

.add-tyres-form__tyre-container > .row {
  flex: 1;
}

.add-tyres-form__tyre-container .tyre-container__image-right {
  margin-left: 90px;
  margin-right: 40px;
}

.add-tyres-form__tyre-container .tyre-container__image-left {
  margin-left: 40px;
  margin-right: 90px;
}

.add-tyres-form__middle-image-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 0;
}
