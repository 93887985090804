.search-alerts-table tbody tr:nth-of-type(2n + 1) {
  background-color: var(--white-color) !important;
}
.search-alerts-table tbody tr td:nth-of-type(2n) {
  text-align: right;
  padding-right: 15px;
}

.box-of-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
}

.fa-trash-alt {
  color: var(--primary-color);
}

.search-alerts__trash-icon {
  color: var(--primary-color);
  font-size: large;
  padding-left: 15px;
}

.notification-button {
  font-weight: normal !important;
  text-transform: none !important;
  height: 30px !important;
  min-width: 140px;
  width: unset !important;
}

@media (max-width: 500px) {
  .notifications-title {
    font-size: var(--font-size-xxl);
  }
}
