@media (max-width: 576px) {
  .au-hero-content-image {
    display: none;
  }

  .au-business-model {
    padding-right: 1rem !important;
  }
}

@media screen and (min-width: 577px) {
  .au-business-model {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 769px) {
  .au-business-model__content-column {
    padding-right: 0 !important;
  }

  .story__year {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .sell-card-wrapper,
  .buy-card-wrapper {
    margin: 0 !important;
  }

  .au-business-model-content-left {
    padding: 0 !important;
    margin-bottom: 30px;
  }

  .au-business-model-content-right {
    padding: 0 !important;
  }

  .au-business-model__image {
    min-width: 100% !important;
  }

  .story__tile-rectangle {
    display: none;
  }

  .story__timeline {
    display: none;
  }

  .story__timeline-year {
    display: none;
  }
}

/* -- HERO SECTION -- */

.au-hero {
  padding: 70px 120px;
  min-height: 75vh;
  align-items: flex-start;
}

.au-hero__check-example {
  max-width: 550px;
}

.au-hero-content-image {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 65vw;
  max-width: 700px;
}

.au-hero-subtitle {
  max-width: 477px;
  margin-top: 50px;
  margin-bottom: 40px;
}

.au-hero .hero-content {
  position: relative;
}

/* -- WHO WE ARE SECTION -- */

.who-we-are {
  background: var(--white-color);
}

.who-we-are__tile-wrapper {
  margin-top: 80px;
}

.who-we-are__tile {
  border-bottom: 1px solid var(--primary800);
}

.who-we-are__tile-title {
  font-weight: 700;
  font-size: 88px;
  line-height: 96px;
  margin-bottom: 20px;
}

.who-we-are__tile-description {
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

/* -- OUR BUSINESS MODEL -- */

.au-business-model {
  min-height: 550px !important;
  border-bottom: 1px solid var(--gray200);
}

.au-business-model-content-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.au-business-model__background-right {
  width: 30%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--primary-color);
}

.au-business-model__image {
  min-width: 400px;
}

/* -- TEAM LEADERSHIP -- */

.team-leadership.section {
  min-height: 700px;
  border-bottom: 1px solid var(--gray200);
}

.team-leadership__row {
  margin-top: 80px;
}

.team-leadership__tile-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.team-leadership__tile-description {
  position: relative;
  margin-top: 20px;
}

.team-leadership__tile-description a {
  position: absolute;
  right: 0;
  bottom: 2px;
}

/* -- OUR STORY -- */
.story {
  background: var(--gray100) !important;
  min-height: 600px;
}

.story__tile-wrapper {
  margin-top: 100px;
}

.story__tile {
  position: relative;
  height: 100%;
}

.story__tile-text {
  margin-bottom: 15px;
}

.story__tile-rectangle {
  position: absolute;
  right: 0;
  bottom: -81px;
  z-index: 2;
}

.story__year {
  margin-bottom: 40px;
}

.story__timeline-year {
  margin-bottom: 40px;
}

.story__timeline {
  width: 100%;
  border-top: 2px solid var(--primary800);
  z-index: 1;
}
