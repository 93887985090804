.stripe-direct-debit-wrapper {
  min-height: 100vh;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
}

.stripe-direct-debit-title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 35px;
  font-weight: 700;
}

.stripe-direct-debit-text {
  text-align: center;
  font-size: 20px;
}

.common-button--link {
  text-transform: uppercase !important;
}

.common-button--link:hover {
  color: var(--white-color);
}
