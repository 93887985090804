.registration-role-label {
  color: #000 !important;
  font-weight: 800;
}

.registration-role-paragraph {
  color: #aaa;
  padding-left: 35px;
  text-align: justify;
}
