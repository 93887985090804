.my-organization-nav-col {
  flex-basis: 230px;
  padding: 0;
  border-right: 1px solid var(--disabled-color);
}

@media (max-width: 767px) {
  .my-organization-nav-col {
    display: flex;
    flex-basis: initial;
    justify-content: center;
    padding: 0 20px;
    border-right: none;
    flex-wrap: wrap;
  }
}

@media (min-width: 768px) {
  .my-organization-children-col {
    padding-left: 60px;
  }
}

.my-organization-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.informations-box {
  width: 100%;
  padding: 5px;
  background-color: var(--white-color);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  object-fit: contain;
  border-radius: 6px;
}
@media (min-width: 1137px) {
  .informations-box {
    height: 100%;
  }
}

.informations-box--left,
.informations-box--right {
  padding-right: 15px;
  padding-left: 15px;
}

.informations-box--large {
  min-height: 353px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.informations-box--right {
  justify-content: flex-start;
}

.informations-box--large__chart-wrapper {
  flex-grow: 1;
  min-height: 353px;
  flex-direction: column;
  display: flex;
}

.informations-box--large__chart-wrapper--right {
  align-items: flex-end;
}

.informations-box--large__chart-wrapper--left {
  align-self: stretch;
}

.informations-box--large__chart-wrapper--right {
  align-items: flex-end;
}

.informations-box--large__circle-progress-wrapper {
  position: relative;
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.informations-box--large__stats-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.informations-box--large__icon-wrapper {
  width: 40px;
  height: 31px;
}

.informations-box--large__icon {
  width: 100%;
}

.informations-box--large__counter {
  font-size: var(--font-size-xxxl);
  font-weight: bold;
}

.informations-box--large__title {
  text-transform: uppercase;
  font-size: 15px;
  color: var(--gray100);
}

.informations-box--large__chart-stats-wrapper {
  display: flex;
  flex-direction: column;
}

.informations-box--large__chart-stats-wrapper--right {
  margin-left: 10px;
}

.informations-box--large__chart-stats-wrapper--left {
  margin-right: 10px;
}

.informations-box--large__single-stats-wrapper {
  width: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid var(--gray100);
  margin-left: 5px;
}

.cancelled-stat {
  border-top: 1px solid var(--gray100);
  margin-right: 10px;
}

.informations-box--large__single-stats-wrapper:last-child {
  border-bottom: none;
}

.informations-box--large__single-stats {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  text-align: left;
}

.informations-box--large__single-stats-title {
  font-size: var(--font-size-l);
  color: var(--primary-color);
}

.informations-box--large__single-stats--sold {
  color: var(--dark-green-color);
}

.informations-box--large__single-stats-counter {
  font-size: var(--font-size-xl);
  font-weight: bold;
}

.informations-box__arrow-icon {
  color: var(--gray100);
}

.informations-box--small {
  height: 121px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.informations-box--small__inner-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.informations-box--small__inner-wrapper:last-child {
  padding-top: 5px;
}

.informations-box--small__inner-wrapper:last-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: var(--gray100);
}

.informations-box-small__icon-wrapper {
  width: 50px;
}

.informations-box-small__icon {
  width: 100%;
}

.informations-box-small__stats-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.informations-box-small__stats-title {
  color: var(--gray100);
  font-size: var(--font-size-l);
}

.informations-box-small__stats-counter {
  color: var(--black100);
  font-size: var(--font-size-xxl);
  font-weight: bold;
}

.informations-box-small__title {
  font-size: var(--font-size-l);
}

.informations-box-small__title--bids {
  color: var(--warning-color);
}

.informations-box-small__title--transactions {
  color: var(--active-color);
}

.informations-form {
  width: 100%;
}

.my-organization-nav-link {
  color: var(--gray100);
}

@media (max-width: 767px) {
  .my-organization-nav-link {
    margin: 0 10px;
  }
}

.informations-form__pending-field {
  position: relative;
  border: 1px solid var(--yellow600);
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 1;
}

@keyframes pending-information-move-in {
  0% {
    left: 0;
  }
  100% {
    left: -15px;
  }
}

@keyframes pending-information-move-in-text {
  0% {
    left: -23px;
  }
  100% {
    left: -37px;
  }
}

.informations-form__pending-field:before {
  content: '';
  position: absolute;
  top: -1px;
  left: -15px;
  height: calc(100% + 2px);
  width: 15px;
  background-color: var(--yellow600);
  border: 1px solid var(--yellow600);

  animation-name: pending-information-move-in;
  animation-duration: 0.5s;
  z-index: 0;
}

.informations-form__pending-field:after {
  content: 'In verification';
  position: absolute;
  top: 50%;
  bottom: 0;
  left: -37px;
  height: 15px;
  color: var(--black100);
  font-size: 9px;
  font-weight: bold;
  transform: rotate(-90deg) translate(10%, -50%);
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;

  animation-name: pending-information-move-in-text;
  animation-duration: 0.5s;
  z-index: 0;
}

.informations-form__trash-icon {
  position: absolute;
  top: 7px;
  right: 5px;
  color: var(--reject-color);
}

.circle-progress {
  position: relative;
  width: 210px;
  height: 210px;
  transform: rotate(-90deg);
}

.circle-progress__circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 10;
}

.information-form_trash-button {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;
  height: 25px;
  background-image: url('../../img/trashIcon.svg');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  z-index: 1;
  outline: none !important;
}

.employees-table-row__action-button,
.invoice-table-row__action-button {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  outline: none !important;
}

.invoice-table-row__action-button {
  width: 24px;
  height: 24px;
  background-image: url('../../img/icons/pdf.svg');
}

.employees-add-new-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .employees-add-new-button-wrapper,
  .employees-search-button-wrapper {
    justify-content: center;
  }
}

.transactions-search-button-wrapper {
  margin-top: 10px;
}

.invoices-search-button-wrapper {
  margin-top: 10px;
}

.employees-search-button-wrapper {
  display: flex;
  margin-top: 10px;
}

.credit-card-exp-date-separator {
  margin-top: 25px;
}

.wallet-details__no-card {
  border-radius: 4px;
  color: var(--white-color);
  padding: 10px;
}

.wallet-details__no-card {
  margin-bottom: 5px !important;
  background-color: var(--primary-color);
}
