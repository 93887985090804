.styled-header {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: var(--primary-color);
  z-index: 1000;
  height: 70px;
  color: white;
  padding-left: 120px;
  padding-right: 120px;
}

.styled-header.header-auth {
  padding-left: 40px;
  padding-right: 40px;
}

.styled-header .common-button {
  padding: 12px 20px;
}

.styled-header .page-logo-link {
  width: 210px !important;
}

.header-container {
  background-color: var(--primary-color);
  margin: 0;
  padding-left: 120px;
  padding-right: 120px;
  height: 100%;
  min-width: 100% !important;
}

.page-logo-link {
  margin-right: 20px;
  width: 300px;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .page-logo-link {
    text-align: center;
  }
}

@media (max-width: 991px) {
  .page-logo-link {
    width: 300px;
  }
}

.page-nav__item {
  position: relative;
  margin-right: 15px;
}

.page-nav__item:after {
  content: '';
  width: 1px;
  height: 100%;
  background-color: var(--gray100);
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 991px) {
  .page-nav__item:after {
    display: none;
  }
  .header-search-button-wrapper {
    margin-top: 15px;
  }
}

.page-nav__item:last-child:after {
  display: none;
}

.page-nav__link {
  margin-right: 15px;
  color: var(--gray100) !important;
}

@media (min-width: 992px) {
  .page-nav__link {
    font-size: 12px !important;
    white-space: nowrap;
    margin-right: 10px;
  }
  .page-nav__item {
    margin-right: 10px;
  }
}
@media (min-width: 1100px) {
  .page-nav__link {
    font-size: 13px !important;
  }
}
@media (min-width: 1250px) {
  .page-nav__link {
    font-size: 14px !important;
    margin-right: 15px;
  }
  .page-nav__item {
    margin-right: 15px;
  }
}

.page-nav__link:hover,
.page-nav__link--active {
  color: var(--primary-color) !important;
}

.navbar-loader {
  height: var(--navbar-loader-height);
  width: 50%;
  position: absolute;
  left: 0;
  bottom: calc(var(--navbar-loader-height) * -1);
  background-color: var(--primary-color);
  animation: navbar-loader-animation 1s infinite linear;
}

@keyframes navbar-loader-animation {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(300%);
  }
}

.navbar-toggler {
  position: absolute;
  top: calc(50% - 13px);
  right: 0;
}

.navbar-toggler .navbar-kebab {
  width: 5px !important;
  height: 5px !important;
  background: white;
}

.header-nav__item:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.header-nav__item:first-child a {
  min-width: 110px;
  width: auto;
  position: relative;
}

.header-nav__item:first-child a:hover,
.header-nav__item:first-child a:not(.scroller).active {
  font-weight: bold;
}

@media (min-width: 992px) {
  .header-nav__item:first-child a::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: white;
    opacity: 0;
    transition: opacity 0.6s ease;
  }

  .header-nav__item:first-child a:hover::after {
    opacity: 1;
  }

  .header-nav__item:first-child a:hover::after,
  .header-nav__item:first-child a:not(.scroller):active::after,
  .header-nav__item:first-child a:not(.scroller).active:focus::after,
  .header-nav__item:first-child a:not(.scroller).active::after {
    opacity: 1;
  }
}

.navbar-kebab {
  color: white;
}

@media (max-width: 992px) {
  .header__navbar {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100% !important;
  }

  .header-navbar-wrapper {
    position: absolute;
    top: 100%;
    background-color: var(--primary-color);
    border-top: 1px solid darkgray;
    width: 100%;
    height: auto !important;
  }

  .header-nav {
    display: flex;
    flex-direction: column !important;
    flex-wrap: wrap !important;
  }

  .header-nav__item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .header-nav__item:last-child {
    margin: auto;
    gap: 40px;
  }
}

.header__navbar {
  position: relative;
  width: 1300px;
  padding: 0 40px;
}
