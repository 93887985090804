.cookies-policy-modal {
}

.cookies-policy-modal .modal-content {
  padding: 40px;
}

.cookies-policy-modal__header {
  padding: 20px 0 20px 0;
  text-align: left;
  border: 0;
}

.cookies-policy-modal__header .modal-title {
  font-weight: bold;
  font-size: var(--font-size-xl);
  padding-top: 5px;
}
.cookies-policy-modal__body {
  font-size: var(--font-size-base);
  margin: 2px 0 2px 0;
}
.cookies-policy-modal__footer {
  border: 0;
}
