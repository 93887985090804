.hot-auction {
}

.hot-auction-container {
  max-width: 700px;
  padding: 20px;
}

.hot-auction-container__preview {
  margin-top: 40px;
  margin-bottom: 40px;
}

.auction-preview {
  border: 1px solid #e4e7ec;
  padding: 40px 29px 40px 29px;
}

.auction-preview__timer {
  max-width: 250px;
}

.auction-preview__timer-progress {
  width: 100%;
  margin-top: 8px;
  border-radius: 0;
}

.auction-preview__timer-progress .progress-bar {
  background-color: var(--light-primary-color);
}

@media (max-width: 576px) {
  .hot-auction-container {
    margin: 0 !important;
    padding: 1rem;
  }

  .auction-preview__title-wrapper {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .auction-preview__title-wrapper {
    margin-bottom: 40px;
  }

  .hot-auction-container {
    margin-top: 50px;
  }

  .hot-auction-container__actions div {
    padding: 0;
  }
}
