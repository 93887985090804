:root {
  --my-organization-nav-link-border-width: 3px;
}

.common-switch-button {
  position: relative;
  width: var(--common-switch-button-width);
  height: 25px;
  border-radius: 12px;
  background-color: var(--white-color);
  cursor: pointer;
  border: none;
  outline: none !important;
}

.common-switch-button:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -10px);
  width: var(--common-switch-button-pointer-dimention);
  height: var(--common-switch-button-pointer-dimention);
  border-radius: 50%;
}

.common-switch-button--on:before {
  transform: translate(var(--common-switch-button-translate-x), -50%);
  background-color: var(--primary-color);
  transition: transform 0.3s;
}

.common-switch-button--off:before {
  transform: translate(var(--common-switch-button-x-padding), -50%);
  background-color: var(--gray100);
  transition: transform 0.3s;
}

.common-switch-button--on .common-switch-button__label {
  color: var(--primary-color);
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.common-switch-button--off .common-switch-button__label {
  color: var(--gray100);
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.form-input__length-counter {
  font-size: 12px;
  text-align: right;
}

.label-required::after {
  content: ' *';
  color: var(--error-text-color);
}

.react-date-picker-top .rdtPicker {
  margin-top: -340px !important;
}

.react-date-picker-top .rdtPicker::before,
.react-date-picker-top .rdtPicker::after {
  top: auto !important;
  bottom: -5px !important;
  border-bottom: 0 !important;
  border-top: 0.4em solid var(--white-color) !important;
}

.form-input-field-label,
.form-select-field-label,
.form-date-picker-field-label,
.form-check-label,
.form-currency-input-field-label {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: bold;
  color: var(--form-input-field-label-color);
}

.form-input-field-label--bold {
  font-weight: 700;
}

.common-button {
  border: none;
  width: fit-content;
  height: 40px;
  padding: 12px 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  outline: none !important;
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem;
}

.common-nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 250px;
  height: 40px;
  text-transform: uppercase;
  font-weight: bold;
  outline: none !important;
}

.common-button__content {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.common-nav-link:hover {
  color: var(--primary-color);
}

.common-button--large {
  height: 48px !important;
}

.common-button--small {
  height: 40px !important;
}

.common-button--medium {
  height: 32px !important;
}

.common-nav-link--active {
  position: relative;
  color: var(--primary-color) !important;
}

.common-button__icon {
  width: 24px;
  height: 24px;
}

.common-button__icon-left {
  position: absolute;
  top: 2px;
  left: -20px;
  height: 1.1rem;
}

.common-button:disabled,
.common-button:disabled:active,
.common-button:disabled:focus,
.common-button.disabled-button,
.common-button.disabled-button:hover {
  background-color: var(--gray300) !important;
  color: var(--white-color) !important;
}

.common-button:disabled:hover {
  cursor: not-allowed !important;
}

.common-shadow-loader {
  position: absolute;
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #ffffff99;
}

.common-nav-link--active:after {
  content: '';
  position: absolute;
  top: 0;
  right: calc(var(--my-organization-nav-link-border-width) / 2 * -1);
  width: var(--my-organization-nav-link-border-width);
  height: 100%;
  background-color: var(--primary-color);
}

@media (max-width: 767px) {
  .common-nav-link--active:after {
    display: none;
  }
}

.table-responsive {
  overflow-x: auto !important;
}

.common-table {
  font-size: 14px;
}

.common-table__action-column tr td:last-child,
.common-table__action-column tr th:last-child {
  width: 120px;
  text-align: center;
}

.common-table__header {
  background-color: var(--primary100);
  border-bottom: 1px solid var(--primary800);
}

.common-table-header__cell {
  text-transform: inherit !important;
  padding: 10px 12px !important;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: var(--primary800);
}

.common-table-header__action-column {
  width: 100px;
  text-align: center;
}

.common-table__action-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.common-table__body td {
  padding: 12px 10px !important;
}

.common-table-row-even {
  background-color: var(--white-color);
}

.common-table-data {
  flex-grow: 1;
}

.common-table__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray300);
  font-size: 14px;
  height: 48px;
  width: 100%;
  margin-top: -26px;
  border-bottom: 1px solid var(--primary100);
}

.employee-common-table__status-box {
  padding: 2px 8px;
  width: fit-content !important;
  font-weight: bold;
}

.page-item .page-link {
  background: var(--white-color) !important;
  color: var(--black100) !important;
}

.form-group-checkbox-inline-wrapper {
  display: flex;
  gap: 30px;
}

.form-group-toggle-checkbox {
  display: flex;
  flex-direction: column;
}

.form-group-checkbox-field--disabled {
  cursor: default !important;
}

.form-checkbox__label--disabled {
  cursor: default !important;
}

.form-input-field__label,
.form-select-field__label {
  margin-top: 8px;
  color: var(--red-color);
  font-size: 1rem;
}

.form-input-field__suffix {
  background: transparent;
  display: inline;
  position: absolute;
  transform: translateY(-50%);
  right: 10px;
  bottom: 0;
  color: var(--gray100);
}

.form-input-field__suffix-error {
  position: absolute;
  right: 30px;
  bottom: 0;
}

.form-input-field__suffix-padding {
  padding-right: 45px !important;
}

.form-input-field__suffix-padding-error {
  padding-right: 68px !important;
}

.form-date-picker-field-wrapper {
  position: relative;
}

.form-date-picker-field-wrapper .calendar-icon {
  cursor: pointer;
}

.form-date-picker-field__suffix {
  background: transparent;
  display: inline;
  position: absolute;
  transform: translateY(-50%);
  right: 15px;
  bottom: 0;
  color: var(--gray100);
}

.form-date-picker-field__suffix-with-icon {
  position: absolute;
  font-size: 12px;
  transform: translateY(-60%);
  right: 35px;
  bottom: 0;
}

.calendar-icon {
  position: absolute;
  top: calc(50% - 1px);
  right: 10px;
  transform: translateY(-50%);
}

.common-delete-button__icon-wrapper {
  position: relative;
  text-decoration: none;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  background-color: var(--red700);
}

.common-icon-button__wrapper,
.common-edit-button__icon-wrapper {
  position: relative;
  text-decoration: none;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  background-color: var(--primary600);
}

.common-edit-button__icon-wrapper button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-icon-button__wrapper button:hover,
.common-edit-button__icon-wrapper button:hover {
  background-color: var(--primary700);
}

.common-icon-button__wrapper button:active,
.common-icon-button__wrapper button.active,
.common-edit-button__icon-wrapper button:active,
.common-edit-button__icon-wrapper button.active {
  background-color: var(--primary800);
}

.common-delete-button__icon-wrapper button:hover {
  background-color: var(--red800);
}

.common-delete-button__icon-wrapper button.active,
.common-delete-button__icon-wrapper button:active {
  background-color: var(--red400);
}

.common-icon-button__wrapper img,
.common-edit-button__icon-wrapper img,
.common-delete-button__icon-wrapper img {
  width: 20px;
  height: 20px;
}

.common-delete {
  text-decoration: none;
  outline: none !important;
  box-shadow: none;
}

.form-select-field-hidden {
  display: none;
}
.form-select-field-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.form-select-field-inner-wrapper {
  display: flex;
  align-items: center;
}

.form-select-field-inner-wrapper > div {
  flex-grow: 1;
}

.form-select-field__suffix {
  margin-left: 10px;
  font-weight: bold;
  color: var(--gray100);
}

.mileage-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.rdt .form-control {
  background-color: var(--white-color);
  cursor: pointer;
}

.rdt-invalid .form-control {
  border: 1px solid var(--reject-color);
}

.common-button--waiting {
  background-color: #fbc658 !important;
}

.common-button--published {
  background-color: var(--button-primary-color) !important;
}

.common-button--rejected {
  background-color: var(--rejected-color) !important;
}

.common-button--to-accept {
  background-color: var(--active-color) !important;
}

.common-button--sold {
  background-color: var(--dark-green-color) !important;
}
.common-button--canceled {
  background-color: var(--cancelled-color) !important;
}
.common-button--ended {
  background-color: var(--ended-color) !important;
}
.form-async-select-field--disabled {
  cursor: not-allowed;
}

.primary-button {
  background-color: var(--button-primary-color);
  color: var(--white-color);
}

.primary-button:hover {
  background-color: var(--primary700);
  color: var(--white-color);
}

.primary-button:active,
.primary-button.active,
.primary-button:focus {
  background-color: var(--primary800);
  color: var(--white-color);
}

.outline-primary {
  border: 1px solid var(--primary600);
  background-color: white;
  color: var(--button-primary-color);
}

.outline-primary:hover {
  border: 1px solid var(--primary700);
  background-color: white;
  color: var(--primary700);
}

.outline-primary.active,
.outline-primary:active {
  border: 1px solid var(--primary800);
  background-color: white;
  color: var(--primary800);
}

.outline-primary:disabled,
.outline-primary.disabled {
  background-color: white !important;
  color: var(--gray300) !important;
  border: 1px solid var(--gray300);
}

.success-button {
  background-color: var(--green600);
  color: white;
}

.success-button:hover {
  background-color: var(--green500);
}

.success-button:active,
.success-button.active {
  background-color: var(--green400);
}

.delete-button {
  background-color: var(--red700);
  color: white;
}

.delete-button:hover {
  background-color: var(--red800);
  color: white;
}

.delete-button:active,
.delete-button.active,
.delete-button:focus {
  background-color: var(--red400);
  color: var(--white-color);
}

.success:focus,
.success.active,
.success:hover {
  background-color: var(--button-primary-color) !important;
  color: white !important;
}

.secondary-button {
  background-color: var(--white-color);
  color: var(--button-primary-color) !important;
}

.tertiary-button {
  width: 100% !important;
  background-color: var(--gray100);
  text-transform: none !important;
}

.btn-grey {
  background-color: #f4f3ef;
  color: var(--button-primary-color) !important;
}

.publish-auction-button {
  background-color: var(--active-color) !important;
  color: var(--white-color) !important;
}

.btn-delete {
  background-color: var(--cancelled-color) !important;
  color: var(--white-color) !important;
}

.btn-delete:hover {
  background-color: var(--dark-cancelled-color) !important;
}

.hidden {
  visibility: hidden;
}

.hide {
  display: none;
}

.photo_gallery_popup {
  padding: 32px 170px !important;
  background-color: white !important;
  max-width: 90% !important;
  width: 100% !important;
  margin: 0 !important;
  position: relative;
}

.photo_gallery_close_button {
  background-color: transparent !important;
  z-index: 999;
  position: absolute;
  top: 5px;
  right: 8px;
  font-weight: 900;
  font-size: 20px;
  color: var(--gray100);
}

.photo_gallery_return_button {
  background-color: transparent !important;
  z-index: 999;
  position: absolute;
  top: 5px;
  left: 8px;
  font-weight: 900;
  font-size: 20px;
  color: var(--gray100);
}

.photo_gallery_damage_button {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  position: absolute;
  width: auto;
  font-weight: 900;
}

.damages__warning-icon {
  height: 100px;
  width: 100px;
}

.popup__slider-wrapper .photo_gallery_damage_info {
  width: auto !important;
  margin-right: 5px;
}

.photo_gallery_damage_info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 35px;
  z-index: 20;
  position: absolute;
  top: 5px;
  right: 5px;
  font-weight: 700;
  border: 1px solid var(--primary500);
  background-color: white;
  color: var(--primary500);
  padding: 10px 20px;
}

.photo_gallery_damage_info img {
  margin-bottom: 2px;
}

.popup__damage-type {
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  font-weight: 900;
  font-size: 20px;
  z-index: 999;
  color: var(--primary-color);
}

.popup__slider {
  width: 100%;
  height: calc(100% - 170px);
  transform: none !important;
  overflow: hidden !important;
}

.popup__slider-counter {
  position: absolute;
  bottom: -4px;
  left: 0;
  transform: translate(calc(-100% - 24px));
  color: var(--primary600);
  font-weight: 700;
  font-size: 20px;
}

.popup__slider-close {
  position: absolute;
  top: 10px;
  right: 0;
  transform: translate(calc(100% + 12px));
}

.popup__slider-counter .slider-counter__current {
  color: black;
}

.popup__thumbnails-slider {
  width: 100%;
  margin-top: 20px;
}

@media (min-width: 992px) {
  .popup__thumbnails-slider {
    height: 150px;
  }
}
.popup__thumbnails-slider-1 {
  padding-right: 80%;
}

.popup__thumbnails-slider-2 {
  padding-right: 60%;
}

.popup__thumbnails-slider-3 {
  padding-right: 40%;
}

.popup__thumbnails-slider-4 {
  padding-right: 20%;
}

.popup__slider-wrapper {
  transform: none !important;
  padding: 10px 5px 0 5px;
  position: relative;
}

@media (min-width: 992px) {
  .popup__slider-wrapper {
    height: 80vh;
  }
}

.popup__slider-image {
  width: 100%;
  border-radius: 0;
}

.popup__slide {
  height: 100%;
  transform: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: var(--black-color);
}

.popup__slide-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  background: var(--black-color);
}

@media (max-width: 991px) {
  .popup__slide-image {
    height: initial;
  }
}

.popup__thumbnails-slide {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transform: none !important;
}

.popup__thumbnails-slide-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: var(--black-color);
}

.popup__slide-active {
  outline: var(--light-primary-color) solid 5px;
  outline-offset: -5px;
}

.popup__slider-arrow-button {
  position: absolute;
  top: 50%;
  padding: 0 15px;
  transform: translateY(-50%);
  outline: none !important;
  font-size: 40px;
}

@media screen and (max-width: 1500px) {
  .popup-slider__slide img {
    object-fit: cover !important;
  }
}

.popup-slider__slide:nth-child(1) {
  margin-left: 0 !important;
}
/**/
.popup-slider__slide:last-child {
  margin-right: 0 !important;
}

.popup-slider__slide .popup__slide-button {
  height: 100%;
}

.popup__slider-arrow-button--left {
  left: 0;
  top: 0;
  transform: translate(calc(-100% - 32px), calc(50% + 20px));
}
.popup__slider-arrow-button--right {
  right: 0;
  top: 0;
  transform: translate(calc(100% + 32px), calc(50% + 20px));
}

.popup__slide-button {
  width: 100%;
}
.popup__damage-counter {
  margin: 0 5px;
  font-size: 20px;
}

.popup__slide-container {
  overflow: visible !important;
}

.car-feature-button,
.car-feature-button--selected {
  display: inline-block;
  width: initial !important;
  height: 30px !important;
  margin: 0 16px 8px 0;
  text-transform: capitalize !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  border-style: solid;
  border-width: 1px;
  padding: 2px 8px;
  color: var(--primary700);
  border-color: var(--primary700);
  background-color: var(--white-color);
}

.car-feature-button--selected {
  color: var(--white-color);
  border-color: var(--primary700);
  background-color: var(--primary700);
}

.button--disabled {
  cursor: default !important;
}

.break-word {
  overflow-wrap: anywhere;
}

.alert-container.alert.alert-new-vehicle-available {
  background-color: var(--alert-new-vehicle-available-color);
  cursor: pointer;
}

.alert-container.alert.alert-new-vehicle-available .close {
  display: none;
}

.refresh-icon {
  margin-right: 10px;
}

.card-element-wrapper,
.card-expiry-element-wrapper,
.card-number-element-wrapper {
  padding: 12px;
}

.form-control--disabled {
  background-color: #e3e3e3;
}

.form-field--disabled > .form-control {
  background-color: #f2f2f2 !important;
  border-color: #e6e6e6 !important;
  cursor: not-allowed !important;
}

.auction-item__status-type {
  font-weight: 700;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auction-seller-section__draft-buttons-wrapper,
.auction-seller-section__draft-buttons-wrapper--with-launch {
  padding: 0;
}

@media (max-width: 575px) {
  .auction-details__buttons-section-wrapper {
    flex-direction: column !important;
  }
  .auction-details__buttons-section-wrapper .common-button:nth-child(2),
  .auction-details__buttons-section-wrapper--single-button .common-button,
  .auction-details__buttons-section-wrapper .common-button.btn-success:nth-child(2) {
    margin-top: 15px !important;
  }
  .auction-details__launch-button,
  .auction-details__relaunch-button--ended {
    justify-content: center !important;
  }
  .auction-details__relaunch-button--ended {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .auction-details__buttons-section-wrapper--buyer-waiting {
    margin: 15px 0;
  }
}

@media (max-width: 991px) {
  .auction-details__buttons-section-wrapper {
    margin-top: 10px;
  }
}

.auction-details__buttons-section-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auction-details__buttons-section-wrapper--single-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.auction-details__buttons-section-wrapper .common-button,
.auction-details__buttons-section-wrapper .common-button.btn-success {
  margin: 0 !important;
}

.auction-details__buttons-section-wrapper .common-button.btn-delete {
  margin-top: 15px !important;
}

.auction-details__launch-button {
  align-self: flex-start;
  display: flex;
  justify-content: flex-end;
}

.auction-details__relaunch-button--ended {
  align-self: flex-start;
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.auction-details__relaunch-button--cancelled {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.auction-details__buttons-section-wrapper--buyer-waiting {
  margin-top: 15px;
}

.react-select__control {
  border-radius: 0 !important;
  border: 1px solid var(--primary-color) !important;
  max-height: 41px !important;
  padding: 3px !important;
}

.react-select__control:not(:disabled):not(.react-select__control--is-disabled):hover {
  cursor: pointer;
}

.react-select__single-value {
  color: #66615b !important;
  font-size: 14px;
}

:not(.react-select__control-error)
  > .react-select__control:not(:disabled):not(.react-select__control--is-disabled)
  .react-select__indicator-separator {
  background-color: var(--primary-color) !important;
}

.react-select__control-error
  > .react-select__control:not(:disabled):not(.react-select__control--is-disabled)
  .react-select__indicator-separator {
  background-color: var(--error-text-color);
}

:not(.react-select__control-error)
  > .react-select__control:not(:disabled):not(.react-select__control--is-disabled)
  .react-select__dropdown-indicator {
  color: var(--primary-color) !important;
}

.react-select__control-error
  > .react-select__control:not(:disabled):not(.react-select__control--is-disabled)
  .react-select__dropdown-indicator {
  color: var(--error-text-color);
}

.react-select__control-error .react-select__control {
  border: 1px solid var(--red600) !important;
}

.react-select__control--is-disabled,
.react-select__control:disabled {
  border-color: var(--disabled-text-color) !important;
  color: var(--disabled-text-color) !important;
  background-color: var(--disabled-color) !important;
}

.react-select__single-value:disabled,
.react-select__single-value--is-disabled {
  color: var(--disabled-text-color) !important;
}

.form-group .form-control,
.input-group .form-control {
  padding: 10px !important;
}

.dot::before {
  content: '';
  width: 8px;
  height: 8px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  margin: 3px;
  position: relative;
  display: inline-block;
  background-color: var(--primary-color);
  top: 0.13rem;
}

.dot.live-dot::before {
  border: 1px solid var(--success-text-color);
  background-color: var(--success-text-color);
}

.actions-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.form-field__inline-input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.form-field__common-button {
  width: 200px !important;
  height: 44px !important;
}

.page-item .page-link__page {
  box-shadow: none !important;
  border: 0 !important;
  border-radius: 0 !important;
  width: 32px !important;
  height: 32px !important;
}

.page-item.active .page-link__page {
  background-color: var(--primary600) !important;
  color: white !important;
}

.page-item:not(.active) .page-link__page:hover {
  background-color: var(--primary600) !important;
  color: white !important;
}

.page-item:not(.active) .page-link__page:active {
  background-color: var(--primary700) !important;
  color: white !important;
}

.page-item .page-link__prev,
.page-item .page-link__next {
  width: 32px !important;
  height: 32px !important;
  border: 1px solid var(--primary600) !important;
  border-radius: 0 !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-item.disabled .page-link__prev,
.page-item.disabled .page-link__next {
  border: 1px solid var(--gray600) !important;
}

.page-item .page-link__prev:hover,
.page-item .page-link__next:hover {
  border: 1px solid var(--primary800) !important;
}

.pagination .page-link__dots {
  display: flex;
  align-items: flex-end;
  color: black;
  margin-left: 3px;
  margin-right: 3px;
}

.common-sweet-alert__content-wrapper {
  color: var(--black100);
  width: 100% !important;
}

.common-sweet-alert__title {
  font-weight: 400;
  font-size: 20px;
}

.common-sweet-alert__text {
  font-weight: 700;
  font-size: 24px;
}

.common-toggle-switch {
  width: 64px;
  height: 28px;
}

.common-toggle-switch .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.common-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.common-toggle-switch .common-toggle-switch__slider {
  width: 64px;
  height: 28px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray200);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.common-toggle-switch .common-toggle-switch__slider:after {
  position: absolute;
  right: 7px;
  top: 2px;
  color: var(--black100);
  content: 'NO';
  font-size: 14px;
  font-weight: 400;
  z-index: 1;
}

.common-toggle-switch .common-toggle-switch__slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  z-index: 2;
}

.common-toggle-switch input:disabled + .common-toggle-switch__slider:before,
.common-toggle-switch input.disabled + .common-toggle-switch__slider:before {
  background-color: var(--gray100);
}

.common-toggle-switch input:disabled + .common-toggle-switch__slider:after,
.common-toggle-switch input.disabled + .common-toggle-switch__slider:after {
  color: var(--gray300);
}

.common-toggle-switch input:checked + .common-toggle-switch__slider:after {
  color: var(--green800);
  left: 7px;
  top: 2px;
  content: 'YES';
}

.common-toggle-switch input:not(:disabled):checked + .common-toggle-switch__slider {
  background-color: var(--green100);
}

.common-toggle-switch input:not(:disabled):focus + .common-toggle-switch__slider {
  box-shadow: 0 0 1px var(--green100);
}

.common-toggle-switch input:not(:disabled):checked + .common-toggle-switch__slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}

.interested-button:hover {
  background-color: var(--primary500);
  border-color: var(--primary500);
  color: white;
}

.interested-button:active,
.interested-button.active {
  background-color: var(--button-primary-color);
  border-color: var(--button-primary-color);
  color: white;
}

.form-check-sign::before,
.form-check-sign::after {
  width: 22px !important;
  height: 22px !important;
  margin-top: 0 !important;
  top: 2px !important;
}

.form-check .form-check-sign::after {
  top: 0 !important;
  font-size: 14px;
}

.page-title {
  font-size: 1.35rem !important;
  line-height: 1.8rem !important;
}

.react-select__menu {
  z-index: 99999 !important;
}