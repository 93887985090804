.auction-place-bid-box {
  margin: 20px;
  padding: 20px;
  background-color: var(--green50);
}

.auction-detail-buyer--cancelled {
  display: flex;
  justify-content: space-between !important;
}

.auction-detail-buyer__arrow-icon {
  margin-right: 5px;
  color: var(--primary-color);
}

.auction-detail-buyer__email {
  word-break: break-word;
}

.auction-detail-buyer__section-title {
  font-weight: bold;
  color: var(--gray100);
}

.auction-place-bid-box__form {
  width: 100%;
}

.auction-detail-bid__currency-input {
  max-width: 315px;
  margin: 0 auto;
}

.my-bids-search-button-wrapper {
  margin-top: 10px;
}

.auction-place-bid-box__send-button {
  padding: 0 10px !important;
  width: 100% !important;
}

.auction-place-bid-box__send-button .common-button__icon {
  width: 20px;
  height: 20px;
}

.buyer-section__send-button-wrapper {
  justify-content: end !important;
}
@media screen and (max-width: 526px) {
  .auction-place-bid-box__send-button {
    margin-top: 18px;
    margin-left: 0;
  }
  .buyer-section__send-button-wrapper {
    justify-content: center !important;
  }
}

@media (max-width: 992px) {
  .auction-place-bid-box {
    margin: 0;
  }
}
