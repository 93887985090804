:root {
  --black100: #252422;
}

.summary__video_player .video-react-control-bar {
  background-color: var(--black100);
  border: none !important;
}

.video-react-video:focus {
  border: none !important;
}

.summary__slider_video_container {
  background-color: var(--black-color);
}

.video-react_popup_slider {
  padding-top: 0 !important;
  display: contents;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  height: 100% !important;
}

.my-bids-box-of-car {
  height: 200px;
  border-radius: 0;
}

.my-vehicles-box-of-car {
  background-color: var(--white);
  height: 200px;
  border-radius: 0;
}

.my-vehicles-img-in-box {
  border-radius: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.auction-bar-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  font-size: 0.85rem;
  margin: 0 !important;
  width: 100%;
}

.my-vehicles-back-wrapper {
  line-height: 1rem;
  padding: 0.5rem 0;
}
.my-vehicles-back-wrapper a {
  color: #000000;
}

.my-vehicles-back-wrapper span {
  padding-left: 0.3rem;
}

.my-vehicles-back-icon {
  width: 10px;
  height: 14px;
}

.my-vehicles-search-button-wrapper {
  margin-top: 10px;
}

.auction-detail-seller .col:first-child .status-block {
  width: 100%;
  height: 25px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  line-height: 24px;
}

.auction-detail-seller .col:nth-child(2) {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.auction-detail-seller-sold .col:nth-child(2) {
  display: block;
  align-items: start;
}

.auction-detail-seller .auction-detail-bid {
  font-size: 24px;
  color: #252422;
  font-weight: 600;
}

.auction-detail-seller-draft .col:nth-child(2) button:first-child,
.auction-detail-seller-to-accept .col:nth-child(2) button:first-child {
  margin-left: 10px;
}

.auction-basic-data__column {
  gap: 8px;
}

@media screen and (max-width: 575px) {
  .auction-seller-section__draft-buttons-outer-col {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .auction-seller-section__draft-buttons-wrapper--with-launch {
    order: 1;
  }
}

@media screen and (max-width: 767px) {
  .main-description-pattern-wrapper {
    flex-wrap: wrap !important;
  }
}

.auction-seller-section__draft-buttons-outer-col {
  display: flex;
  align-items: center;
  padding: 15px;
}

.auction-seller-section__draft-buttons-outer-row {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
}

@media (max-width: 575px) {
  .auction-seller-section__draft-buttons-wrapper {
    flex-direction: column;
  }
}

.auction-seller-section__draft-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 575px) {
  .auction-seller-section__draft-buttons-wrapper--with-launch {
    align-items: center !important;
  }
}

.auction-seller-section__draft-buttons-wrapper--with-launch {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.my-vehicles-header {
  gap: 2px;
}

.my-vehicles-header__item:nth-child(1) {
  padding-left: 0 !important;
}

.my-vehicles-header__item {
  position: relative;
  flex: 1;
}

.my-vehicles-header__item .common-button {
  background-color: transparent;
  color: var(--primary-color) !important;
  padding: 10px;
  /*min-width: 120px !important;*/
  width: 100% !important;
  font-size: 0.8rem;
  font-weight: inherit;
  height: auto !important;
}

.my-vehicles-header__item .common-button::before {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100% !important;
  height: 2px;
  background-color: var(--primary-color);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.my-vehicles-header__item .common-button:hover::before {
  background-color: var(--primary700);
}

.my-vehicles-header__item .common-button:hover {
  font-weight: 700;
  background-color: transparent !important;
  color: var(--primary700) !important;
}

.my-vehicles-header__item .common-button.selected {
  font-weight: 700;
  background-color: transparent !important;
  color: var(--primary-color) !important;
}

.my-vehicles-header__item .common-button:active {
  color: var(--primary600) !important;
}

.my-vehicles-header__item .common-button:hover::before,
.my-vehicles-header__item .common-button.selected::before {
  opacity: 1;
}

.main-description-container__basic-data {
  max-height: 48px;
}

.main-description-container__basic-data .search-time,
.main-description-container__basic-data .auction-end,
.main-description-container__basic-data .bid-label,
.main-description-container__basic-data .bid-price-label {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.main-description-container__info-bar {
  background-color: #fff;
  border: 1px solid #d0d5dd;
  padding: 15px 30px;
}

.main-description-container__info-bar .search-time,
.main-description-container__info-bar .ends-time {
  font-size: 18px;
}

.main-description-container__info-bar .bid-price-label {
  font-size: 1.35rem;
}

.main-description-container__item-title {
  color: var(--gray400);
  font-weight: 600;
}

.bid-label-danger-color {
   color: #eb002f
}

.bid-label-black-color {
  color: var(--black100)
}

.main-description-pattern-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100% !important;
  flex-wrap: nowrap;
  flex: 1;
  gap: 20px;
}

.main-description-pattern-item {
  flex: 1;
}

.main-description-container__basic-data .main-description-pattern-item:nth-child(-n + 2) {
  flex: 1;
  border-right: 1px solid var(--gray200);
}

.auction-favorite {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 100%);
  width: 24px;
  height: 24px;
}

.auction-favorite.active:before {
  content: url('../../img/star.png');
}

.auction-favorite:not(.active)::before {
  content: url('../../img/star-empty.png');
}

.auction-favorite:hover {
  cursor: pointer;
}

.auction-favorite.active:hover::before {
  content: url('../../img/star-empty.png');
}

.auction-favorite:not(.active):hover::before {
  content: url('../../img/star.png');
}

.bid-label {
  font-size: 16px;
}

.bid-price-label {
  font-size: 16px;
  font-weight: bold;
}

.bid-price-label__green {
  color: #55b84b;
}

.label-grayed {
  color: #98a2b3;
}

.live-dot::before {
  margin-right: 5px;
}

.auction-status-box {
  border-radius: 0;
  font-size: 0.9rem;
}

.auction-vehicle-images-container {
  display: flex;
  flex-direction: row;
  max-height: 450px;
}

.auction-vehicle-images__solo {
  flex-basis: 100%;
}

.auction-vehicle-images__main {
  flex-basis: 75%;
}

.auction-vehicle-image__wrapper {
  width: 100% !important;
  height: 100% !important;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auction-vehicle-image__wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.auction-vehicle-images__list {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.auction-vehicle-images__list button {
  width: 100%;
}

.auction-vehicle-images__list img {
  object-fit: contain;
}
