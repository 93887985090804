.auth-page {
  min-height: 100vh;
  flex-wrap: nowrap;
}

.auth-page__right-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: inherit;
  flex: 1;
  max-width: 100%;
  background-color: white;
}

.auth-page__left-column {
  display: flex;
  flex-direction: column;
  min-width: 480px;
  max-width: 480px;
  padding: 64px;
  background-color: var(--primary-color);
  color: white;
}

.auth-page__big-title {
  margin-top: 108px;
  line-height: 1.1;
}

.auth-page__white-car {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (max-width: 1010px) {
  .auth-page__left-column {
    flex: 0 0 100%;
    width: auto;
    min-width: 100%;
    max-height: 80px;
    justify-content: center;
    align-items: center;
  }

  .auth-page__right-column {
    height: 100%;
  }

  .auth-page__hide-on-mobile {
    display: none;
  }

  .login-form,
  .forgot-password-form,
  .reset-password-form {
    min-width: auto !important;
  }

  .auth-page {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.login-form,
.forgot-password-form,
.reset-password-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

.login-form h3 {
  margin-bottom: 5px;
}

.login-form__forgot-password-link {
  align-self: flex-end;
  margin-bottom: 18px;
  color: var(--primary-color);
}

.login-form__submit-button,
.forgot-password-form__submit-button {
  align-self: center;
  width: 100% !important;
}

.forgot-password-form__submit-button {
  margin-top: 18px;
}

.login-form__options {
  padding-left: 15px;
  padding-right: 15px;
}
