.styled-footer {
  border-top: 1px solid var(--primary-color);
  background-color: white;
  margin-top: auto;
  z-index: 1;
}

.footer-nav {
  min-width: 248px;
  padding: 20px 0;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
}

.footer-nav__row {
  max-width: 1300px;
  width: 100%;
}

.footer-nav__download-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 767px) {
  .footer-nav__row {
    text-align: center;
  }
  .footer-nav__row-item {
    justify-content: center;
  }
  .footer-nav__list-heading {
    margin-top: 30px;
  }
  .footer-nav__download-container {
    justify-content: center;
  }
}

@media (max-width: 576px) {
  ul.footer-nav__list {
    padding-left: 0;
  }

  .footer-nav__rights {
    margin-bottom: 30px !important;
  }
}

.footer-nav__list {
  padding-left: 70px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  gap: 6px;
}

.footer-nav__list-heading {
  margin-bottom: 5px;
  font-size: var(--font-size-xl);
  text-transform: uppercase;
  font-weight: bold;
}

.footer-nav__list-item {
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.footer-company-info {
  padding: 20px 0;
  background-color: var(--secondary-color);
}

.footer-nav__company-email-link,
.footer-nav__company-email-link:hover {
  color: var(--primary-color);
  font-weight: bold;
}

.footer-nav__download-app-wrapper {
  display: flex;
  flex-direction: column;
}

.footer-nav__download-app-wrapper .download-badge:nth-child(2) {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .footer-nav__download-app-wrapper {
    align-items: center;
  }
}

.footer-logo {
  width: 180px;
}
