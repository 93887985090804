.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 46px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;
}

.infinity-loader-wrapper {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infinity-loader {
  width: 70px;
  height: 70px;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15);
}
