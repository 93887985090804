.common-images-slider {
}

.common-images-slider__main-image {
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: var(--gray100);
  position: relative;
}

.common-images-slider__main-image img.main-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.common-images-slider__slider {
  margin-top: 10px !important;
}

.common-images-slider__slider .slick-track {
  height: 120px;
  overflow: hidden;
}

.common-images-slider__slider .slick-slide {
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  width: fit-content;
}

.common-images-slider__slider .slick-current .slider__images-wrapper{
  outline: var(--light-primary-color) solid 5px;
  outline-offset: -5px;
}

.slider__images-wrapper {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}

.slider__image {
  height: 100%;
  object-fit: contain;
}

.common-images-slider__slider .slick-prev:before {
  content: '<';
  color: red;
  font-size: 30px;
}

.common-images-slider__slider .slick-next:before {
  content: '>';
  color: red;
  font-size: 30px;
}
