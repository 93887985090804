@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import './assets/css/componentStyles/Reset.css';
@import './assets/css/componentStyles/AddVehicle.css';
@import './assets/css/componentStyles/Footer.css';
@import './assets/css/componentStyles/Auth.css';
@import './assets/css/componentStyles/Common.css';
@import './assets/css/componentStyles/Header.css';
@import './assets/css/componentStyles/HomePage.css';
@import './assets/css/componentStyles/AboutUs.css';
@import './assets/css/componentStyles/AccountLayout.css';
@import './assets/css/componentStyles/HotAuction.css';
@import './assets/css/componentStyles/CommonForms.css';
@import './assets/css/componentStyles/MyOrganization.css';
@import './assets/css/componentStyles/MyProfile.css';
@import './assets/css/componentStyles/Search.css';
@import './assets/css/componentStyles/NotFound.css';
@import './assets/css/componentStyles/MyVehicles.css';
@import './assets/css/componentStyles/MyBids.css';
@import './assets/css/componentStyles/CustomSite.css';
@import './assets/css/componentStyles/StripeDirectDebit.css';
@import './assets/css/componentStyles/Register.css';
@import './assets/css/componentStyles/AnalyticsPieChart.css';
@import './assets/css/componentStyles/Settings.css';
@import './assets/css/componentStyles/ImagesSlider.css';
@import './assets/css/componentStyles/CookiesContainer.css';
@import './assets/css/componentStyles/FeeAlertContainer.css';
@import './assets/css/video-react.css';

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/OpenSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

:root {
  /* Pallet */
  --primary100: #d8e2f2;
  --primary200: #bacce9;
  --primary300: #9cb6df;
  --primary400: #7f9fd5;
  --primary500: #6189cc;
  --primary600: #4373c2;
  --primary700: #2f5188;
  --primary800: #1b2e4e;

  --gray50: #f9fafb;
  --gray80: #ffffff80;
  --gray100: #f2f4f7;
  --gray200: #e4e7ec;
  --gray300: #d0d5dd;
  --gray400: #98a2b3;
  --gray500: #667085;
  --gray600: #475467;
  --gray700: #344054;
  --gray800: #1d2939;

  --green50: #f2faf9;
  --green100: #caefe9;
  --green200: #a2e3d8;
  --green300: #79d7c7;
  --green400: #51cbb6;
  --green500: #28bfa5;
  --green600: #00b394;
  --green700: #00957b;
  --green800: #007763;

  --red50: #fdf2f6;
  --red100: #f6d4e0;
  --red200: #eeb6cb;
  --red300: #e499b6;
  --red400: #d87fa1;
  --red500: #cb678d;
  --red600: #bc5079;
  --red700: #ab3b66;
  --red800: #992853;

  --yellow100: #fff4d4;
  --yellow200: #ffebb6;
  --yellow300: #ffe197;
  --yellow400: #ffd679;
  --yellow500: #ffca5b;
  --yellow600: #f7bd40;
  --yellow700: #ecaf2d;
  --yellow800: #dda01d;

  /* Others */
  --primary-color: var(--primary800);
  --light-primary-color: var(--primary600);
  --button-primary-color: var(--primary600);
  --primary-background-color: var(--primary100);
  --secondary-color: #d0d5dd;
  --secondary-text-color: #475467;
  --white-color: #ffffff;
  --button-primary-color-light: #4d6baa;
  --success-color: var(--green200);
  --success-text-color: var(--green800);
  --error-color: var(--red50);
  --error-text-color: var(--red700);
  --warning-color: var(--yellow100);
  --warning-text-color: var(--yellow700);
  --reject-color: #ff0000;
  --active-color: #55b84b;
  --checkbox-color: #174e86;
  --black-color: #000;
  --black100: #252422;
  --reject-color200: #f46363;
  --icon-form-input-field-basic-margin: 10px;
  --font-size-m: 9px;
  --font-size-l: 14px;
  --font-size-xl: 18px;
  --font-size-xxl: 25px;
  --font-size-xxxl: 35px;
  --font-size-base: 1rem;
  --font-line-base: 1.5rem;
  --gray100-alpha: #aaa7a4b3;
  --disabled-color: var(--gray50);
  --disabled-text-color: var(--gray400);
  --common-switch-button-width: 75px;
  --common-switch-button-x-padding: 5px;
  --common-switch-button-pointer-dimention: 20px;
  --common-switch-button-translate-x: calc(
    var(--common-switch-button-width) - var(--common-switch-button-pointer-dimention) -
      var(--common-switch-button-x-padding)
  );
  --container-width: 1140px;
  --form-input-field-label-color: #000;
  --navbar-loader-height: 3px;
  --common-button-disabled-color: #174e8650;
  --red-color: #ff0000;
  --dark-green-color: #297422;
  --rejected-color: #cc2727;
  --cancelled-color: #f46363;
  --dark-cancelled-color: #ff3842;
  --ended-color: #b12983;
  --alert-new-vehicle-available-color: #fb8a58;
  --color-green: #00b394;
}

html {
  height: 100%;
}

body {
  position: relative;
  overflow-x: hidden;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px; /* 16px */
  line-height: 1.5rem;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.bg-grayed {
  background-color: var(--gray100);
}

.page-content {
  display: contents;
  flex: 1;
}

.content-wrapper {
  flex-grow: 1;
  background-color: var(--secondary-color);
}

.content-inner-wrapper {
  max-width: var(--container-width);
  margin: auto;
}

.color-success-text {
  color: var(--success-text-color);
}

.color-green {
  color: var(--green600);
}

.color-primary {
  color: var(--primary-color);
}

.color-light-primary {
  color: var(--light-primary-color);
}

.text-2xs {
  font-size: 0.625rem; /* 10px */
  line-height: 0.75rem;
}

.text-xs {
  font-size: 0.75rem; /* 12px */
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem;
}

.text-3xl {
  font-size: 2rem; /* 32px */
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem; /* 48px */
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem; /* 60px */
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem; /* 72px */
  line-height: 1;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

h1 {
  font-size: 4.5rem; /* 72px */
  line-height: 1;
}

h2 {
  font-size: 2.625rem; /* 42px */
  line-height: 1;
}

h3 {
  font-size: 2rem; /* 32px */
  line-height: 2.25rem;
}

h4 {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem;
}

h5 {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem;
}

button,
input,
optgroup,
select,
textarea {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem; /* 16px */
  line-height: 1.5rem;
  letter-spacing: 0.06rem;
}

.add-organization-custom-button {
  display: inline;
  width: 100%;
  text-transform: none;
  margin-top: 20px;
}

.cancel-button {
  background-color: var(--gray100) !important;
  color: var(--white-color) !important;
}

.sweet-alert {
  border-radius: 0 !important;
}

.sweet-alert-text {
  color: var(--black100);
  font-weight: bold;
}

.sweet-alert__with-icon
  div:not(.actions-wrapper):not(.common-button__content):not(.common-sweet-alert__content-wrapper):first-child {
  width: 40px !important;
  height: 40px !important;
}

.alert-container {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 1000;
}

.navbar {
  margin-bottom: 0;
}

.nav-link {
  padding: 0;
}

.active-nav-link .auth-button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.auth-button {
  outline: none !important;
  padding: 10px 30px !important;
  text-transform: uppercase !important;
  font-weight: bold;
  text-wrap: nowrap;
}

.auth-button--active {
  background-color: var(--primary-color);
  color: var(--white-color) !important;
}

.social-icon-wrapper {
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 991px) and (min-width: 767px) {
  .navbar-collapse {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media (max-width: 767px) {
  .social-icon-wrapper {
    justify-content: center;
    margin: 15px 0;
  }

  .register-wrapper {
    display: flex;
    justify-content: flex-end !important;
  }
}

.social-icon {
  border: none;
  outline: none !important;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background-color: var(--button-primary-color-light);
  border-radius: 5px;
  color: #fff;
}

.input-group,
.form-group {
  margin-bottom: 5px;
}

.social-icon__link,
.social-icon__link:hover {
  color: var(--white-color) !important;
}

.error-label {
  color: var(--red600);
  font-size: 14px;
}

.form-group-radio-field:checked,
.form-group-radio-field:not(:checked) {
  position: absolute;
  left: -9999px;
}

.form-group-radio-field:checked + label,
.form-group-radio-field:not(:checked) + label {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--form-input-field-label-color);
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
}

.form-group-radio-field:checked + label:before,
.form-group-radio-field:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: var(--white-color);
}

.form-group-radio-field:checked + label:before {
  border: 7px solid var(--primary-color);
}

.form-group-radio-field:not(:checked) + label:before {
  border: 2px solid var(--primary-color);
}

.form-group-radio-field:not(:checked) + label:hover:before {
  border: 2px solid var(--primary500);
}

.form-group-radio-field:checked + label:after,
.form-group-radio-field:not(:checked) + label:after {
  content: '';
  width: 7px;
  height: 7px;
  background: white;
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 50%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form-group-radio-field:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.form-group-radio-field:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.icon-form-input-field-wrapper {
  position: relative;
}

.icon-form-input-field-image-wrapper {
  width: 25px;
  height: 25px;
  position: absolute;
  left: var(--icon-form-input-field-basic-margin);
  top: 5px;
  transform: translateY(5px);
}

.icon-form-input-password-field-image-wrapper {
  width: 25px;
  height: 25px;
  position: absolute;
  right: var(--icon-form-input-field-basic-margin);
  top: 4px;
  transform: translateY(5px);
  cursor: pointer;
}

.icon-form-input-password-field-image-wrapper.icon-password-visible {
  top: 4px;
  right: calc(var(--icon-form-input-field-basic-margin));
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 49%;
  transform: translateY(-50%);
}

.search-icon img {
  height: 22px;
}

.button-left-icon {
  width: auto;
  height: auto;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.input-right-icon {
  width: auto;
  height: auto;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.input-icon__disabled,
.form-control--disabled .input-icon,
.form-control:disabled .input-icon {
  filter: opacity(0.2) grayscale(1);
}

.icon-form-input-field {
  padding-left: calc(4 * var(--icon-form-input-field-basic-margin)) !important;
}

.add-profile-details-checkbox-wrapper {
  max-width: 75%;
  margin: 0 auto;
}

.download-badge {
  max-width: 120px;
  margin-bottom: 10px;
}

.mobile-section .download-badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  max-width: max-content;
}

.download-badge__qr {
  width: 140px;
}

.form-control:not(:disabled)::placeholder {
  color: #000000;
  opacity: 0.2;
}

.form-control:disabled::placeholder {
  color: var(--disabled-text-color);
}

.form-control:not(:disabled):-ms-input-placeholder {
  color: #000000;
  opacity: 0.2;
}

.form-control:disabled:-ms-input-placeholder {
  color: var(--disabled-text-color);
}

.form-control:not(:disabled)::-ms-input-placeholder {
  color: #000000;
  opacity: 0.2;
}

.was-validated .form-control[type='Password']:invalid,
.form-control[type='Password'].is-invalid .icon-form-input-password-field-image-wrapper {
  display: none;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--red600);
  background-image: url('assets/img/icons/error.svg');
  background-size: 24px 24px;
}

.form-control:disabled::-ms-input-placeholder {
  color: var(--disabled-text-color);
}

.form-input-field-label__disabled {
  color: var(--disabled-text-color);
}

.form-input-field-label__disabled.label-required:after {
  color: var(--disabled-text-color) !important;
}

.card-element-wrapper.form-control:disabled,
.card-element-wrapper.form-control--disabled,
input.form-control:disabled {
  background-color: var(--disabled-color);
  color: var(--disabled-text-color);
  border-color: var(--disabled-text-color);
  cursor: inherit;
}

.form-control--disabled {
  background-color: var(--disabled-color);
  cursor: inherit;
}

@media (max-width: 576px) {
  .hide-on-mobile-sm {
    display: none;
  }
}

@media (max-width: 768px) {
  .hide-on-mobile-md {
    display: none;
  }
}

.alert {
  border-radius: 0;
  display: flex;
  align-items: center;
}

.alert .close {
  font-size: 36px;
  color: var(--primary-color);
  margin-top: -12px !important;
}

.alert.alert-primary {
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.alert.alert-secondary {
  background-color: var(--gray300);
  color: var(--gray600);
  border: 1px solid var(--gray600);
}

.alert.alert-success {
  background-color: var(--success-color);
  color: var(--success-text-color);
  border: 1px solid var(--success-text-color);
}

.alert.alert-success .close {
  color: var(--success-text-color);
}

.alert.alert-error {
  background-color: var(--error-color);
  color: var(--error-text-color);
  border: 1px solid var(--error-text-color);
}

.alert.alert-danger .close,
.alert.alert-error .close {
  color: var(--error-text-color);
}

.alert.alert-danger {
  background-color: var(--error-color);
  color: var(--error-text-color);
  border: 1px solid var(--error-text-color);
}

.alert.alert-warning {
  background-color: var(--warning-color);
  color: var(--warning-text-color);
  border: 1px solid var(--warning-text-color);
}

.alert.alert-info {
  background-color: #f6f8fc;
  color: var(--primary700);
  border: 1px solid var(--primary700);
}

.alert.alert-success:before {
  content: url('../src/assets/img/icons/success.svg');
  margin-right: 20px;
  width: 32px;
  height: 32px;
}

.alert.alert-info:before {
  content: url('../src/assets/img/icons/info.svg');
  margin-right: 20px;
  width: 32px;
  height: 32px;
}

.alert.alert-danger:before {
  content: url('../src/assets/img/icons/error.svg');
  margin-right: 20px;
  width: 32px;
  height: 32px;
}

.alert.alert-error:before {
  content: url('../src/assets/img/icons/error.svg');
  margin-right: 20px;
  width: 32px;
  height: 32px;
}

.status-box {
  width: 100%;
  height: 100%;
}

.status-box.box-primary {
  background-color: var(--primary-background-color);
  color: var(--primary-color);
}

.status-box.box-secondary {
  background-color: var(--gray300);
  color: var(--gray600);
}

.status-box.box-success {
  background-color: var(--success-color);
  color: var(--success-text-color);
}

.status-box.box-live {
  background-color: var(--green200);
  color: var(--green800);
}

.status-box.box-green {
  background-color: var(--green700);
  color: var(--white-color);
}

.status-box.box-error {
  background-color: var(--error-color);
  color: var(--error-text-color);
}

.status-box.box-danger {
  background-color: var(--error-color);
  color: var(--error-text-color);
}

.status-box.box-red {
  background-color: #eb002f;
  color: var(--white-color);
}

.status-box.box-warning {
  background-color: var(--warning-color);
  color: var(--warning-text-color);
}

.counter-box {
  height: 20px;
  min-width: 20px;
  padding: 4px 3px 3px 3px;
  font-size: 0.625rem; /* 10px */
  line-height: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.counter-box.box-primary {
  background-color: var(--primary700);
  color: white;
}

.counter-box.box-danger {
  background-color: var(--red800);
  color: white;
}

.modal-content {
  border: 0;
  border-radius: 0;
  padding: 40px 80px;
}

.modal-body {
  padding: 0;
}

.modal-dialog {
  max-width: 800px;
}

.line-break {
  width: 100%;
  border-bottom: 1px solid var(--primary100);
  margin-top: 10px;
  margin-bottom: 10px;
}

.line-break.as-row {
  margin-left: 15px;
  margin-right: 15px;
  width: auto;
}

textarea.form-control {
  width: 100%;
  height: auto;
  max-height: 400px;
  resize: both;
}