.fees-modal .fees-modal-content {
    padding: 10px !important;
    font-size: 0.9rem !important;
}

.fees-modal__header {
    padding: 5px 0 5px 0;
    text-align: left;
    border: 0;
}

.modal-content {
    padding: 20px 40px !important;
}

.fees-modal__header .fees-modal-title {
    font-weight: bold;
    font-size: var(--font-size-xl);
    padding-top: 5px;
}
.fees-modal__body {
    font-size: var(--font-size-base);
    margin: 2px 0 2px 0;
}
.fees-modal__footer {
    border: 0;

}

.buyers-fees-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
}

.buyers-fees-table td,
.buyers-fees-table th {
    border: 1px solid #ddd;
    padding: 2px 16px;
    text-align: left;
    font-size: 0.9rem;
}

.fees-modal-font {
    font-size: 0.9rem !important;
}

.fees-modal-table-font {
    font-size: 0.8rem !important;
}

.buyers-fees-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.buyers-fees-table tr {
    border: 1px solid #ddd;
}

.buyers-fees-table th:first-child,
.buyers-fees-table td:first-child {
    width: 50%;
}

.buyers-fees-table th:last-child,
.buyers-fees-table td:last-child {
    width: 50%;
}
