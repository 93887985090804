.register-page {
  min-height: 100vh;
  flex-wrap: nowrap;
}

.register-page__right-column {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex-wrap: inherit;
  flex: 1;
  max-width: 100%;
  padding: 0;
}

.register-page__left-column {
  display: flex;
  flex-direction: column;
  min-width: 480px;
  max-width: 480px;
  padding: 64px;
  background-color: var(--primary-color);
  color: white;
}

.register-page__big-title {
  margin-top: 108px;
}

.register-page__white-car {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 1440px) {
  .register-page__big-title {
    font-size: 1.8rem !important;
    margin-top: 60px;
  }
}

@media screen and (max-width: 1010px) {
  .register-page__left-column {
    flex: 0 0 100%;
    width: auto;
    min-width: 100%;
    max-height: 80px;
    justify-content: center;
    align-items: center;
  }

  .register-page__right-column {
    height: 100%;
    padding: 0;
  }

  .register-page__hide-on-mobile {
    display: none !important;
  }

  .register-page {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.register-price {
  display: grid;
  margin-top: 40px;
}
.price-table {
  border-collapse: collapse;
  width: 100%;
  height: 159px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.price-table tr td {
  max-height: 24px;
}

.price-table tr td:last-child .price__cell-text {
  width: fit-content;
  margin-left: auto;
  margin-right: 20px;
}

.price__cell-text {
  margin-top: 5px;
  margin-bottom: 5px;
}

.register-progress {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 16px;
}

.register-progress__item-title {
  flex-grow: 2;
}

.register-progress__item {
  font-size: 16px;
  padding: 12px;
  border-left: 4px solid white;
}

.register-progress__item-icon {
  flex-basis: max-content;
  margin: 0;
  padding: 0;
}

.register-progress__item-icon > .progress-checked {
  display: inline-block;
  color: #666;
  margin-right: 24px;
}

.register-progress__item-icon > .progress-checked:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: var(--white-color);
}

.register-progress__item-icon > .progress-checked:after {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--primary-color);
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 50%;
}

.register-form-container {
  padding: 64px;
  flex: 1;
}

.register-form-container__title {
  flex: 1;
  max-height: 200px;
}

.register-form-container__title p {
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}

.register-form-container__content {
  flex: 2;
}

.register-form-container .form-input-field-label {
  font-weight: bold;
}

.register-form-container .register-form__role-details {
  gap: 64px;
  margin-top: 64px;
}

.register-form__role-details .dealer-role__option {
  max-width: 450px;
  padding: 20px;
  border: 1px solid var(--secondary-color);
  cursor: pointer;
}

.register-form__role-details .dealer-role__option:hover {
  border-color: var(--primary600);
}

.register-form__role-details .dealer-role__option.option-selected {
  border: 1px solid var(--primary-color);
  cursor: pointer;
}

.register-form__role-details .dealer-role__option > span {
  margin-left: 32px;
}

.register-form__address-details,
.register-form__company-details,
.register-form__profile-details,
.register-form__payment-details,
.register-form__role-details {
  max-width: 1100px;
  margin-top: 92px;
  flex: 1;
  padding-bottom: 64px;
}

.payment-details__table {
  margin-top: 26px;
}

.register-role__dealer-type-option {
  border: 1px solid var(--primary-color);
  padding: 20px;
}

.register-footer {
  height: 80px;
  background-color: #f9fafb;
}

.register-footer {
  position: absolute;
  left: 15px;
  bottom: 0;
  width: 100%;
  align-items: center;
}

.form-select-field-label {
  font-weight: bold;
}

.register-account-button {
  font-weight: normal !important;
  width: auto !important;
}

.register-success-image {
  position: absolute;
  bottom: 64px;
  right: 64px;
}

.register-form-success-button {
  width: 395px !important;
  margin-right: auto !important;
  padding: 22px;
}
