@media (max-width: 576px) {
  .contact-us-right {
    padding-top: 30px;
    padding-bottom: 50px;
  }
}

/** -- Contact us Form -- **/
.contact-us.light .contact-us-left {
  background-color: white;
  color: var(--primary-color);
  padding: 120px;
}

.contact-us.light .contact-us-right {
  background-color: #f2f4f7;
}

.contact-us.light a {
  color: var(--primary-color);
}

.contact-us:not(.light) .contact-us-left {
  background-color: var(--primary-color);
  color: white;
  padding: 80px 100px;
}

.contact-us {
  height: 100%;
}

.contact-us a {
  color: white;
}

.contact-us__title {
  margin-bottom: 100px;
}

.contact-us-form {
  max-width: 500px;
}

.contact-us-card__icon-box {
  width: 48px;
  height: 48px;
  background-color: white;
}

.contact-us-card__icon-box img {
  width: 36px;
}

.form-input-field-label {
  font-weight: bold;
}
