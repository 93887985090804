.search-vehicles-found {
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--black100);
}

.search-sort {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--black100);
  cursor: pointer;
}

.search-sort--time-filter {
  margin-bottom: 10px;
}

.search-box-of-car {
  display: flex;
  min-height: 169px;
  height: auto;
  background-color: var(--white);
  margin-top: 10px;
  margin-left: 0;
  margin-right: 0;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.search-box-of-car:nth-child(1) {
  margin-top: 0 !important;
}

.search-box-of-img {
  width: auto;
  height: 100%;
  /*padding-bottom: 32px;*/
  padding-left: 0;
  padding-right: 0;
}

.search-box-of-img-wrapper {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 270px;
  /*margin-bottom: 32px;*/
}

.organization-role-box {
  /*bottom: 0;*/
  /*position: absolute;*/
  /*color: white;*/
  /*padding: 4px 12px;*/
  /*font-weight: 600;*/
  /*font-size: 12px;*/
  /*width: 100%;*/
  top: 19px;
  position: absolute;
  color: #fff;
  padding: 2px 12px;
  font-weight: 600;
  font-size: 12px;
  width: 200px;
  right: -66px;
  transform: rotate(45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
}

.organization-role-box-franchise,
.organization-role-box-no-reserve {
  background-color: var(--primary-color);
}

.organization-role-box-approved,
.organization-role-box-physical {
  background-color: var(--primary-color);
}

.organization-role-box-list {}

.search-img-in-box {
  height: 100%;
  width: 100%;
  padding: 14px;
  object-fit: contain;
  flex: 1;
}

.search-title {
  object-fit: contain;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.15rem;
  margin-right: 50px !important;
}

.search-year,
.search-registration,
.search-mileage,
.search-parameters,
.search-fuel,
.search-transmission,
.search-city {
  display: flex;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--black100);
  overflow-wrap: anywhere;
}

.search-year::before,
.search-registration::before,
.search-mileage::before,
.search-time::before,
.search-fuel:before,
.search-transmission:before,
.search-city:before {
  content: '';
  width: 25px;
  display: flex;
  justify-content: center;
  padding-right: 5px;
  padding-top: 1px;
  vertical-align: middle;
}

.search-year::before {
  content: url('../../img/search/year.svg');
}

.auction-item__derivative-tooltip .tooltip {
  top: 13px !important;
}

.auction-item__icon {
  width: 26px;
  height: 26px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f4f7;
}

.auction-item__icon img {
  width: 18px;
  height: auto;
}

.search-registration::before {
  content: url('../../img/search/registration.svg');
}

.search-mileage::before {
  content: url('../../img/search/mileage.svg');
}

.empty-search-mileage::after {
  content: none !important;
}

.search-mileage::after {
  content: 'miles';
  margin-left: 5px;
}

.search-fuel::before {
  content: url('../../img/fuelIcon.svg');
}

.search-transmission::before {
  content: url('../../img/transmissionIcon.svg');
}

.search-city::before {
  content: url('../../img/locationPinIcon.svg');
}

.search-content-right {
  text-align: right;
}

.search-bid-button {
  margin: 10px 0 10px auto;
  order: 2;
  position: absolute;
  right: 10px;
  bottom: -5px;
}

@media (min-width: 585px) {
  .search-bid-button {
    right: 10px;
    bottom: -25px;
    position: absolute;
  }
}
@media (min-width: 768px) {
  .search-bid-button {
    bottom: 0;
    right: 10px;
  }
}

@media (min-width: 998px) {
  .search-bid-button {
    position: relative;
    right: 0;
  }
}
.search-time {
  white-space: nowrap;
  color: black;
}

.ends-time,
.search-time,
.search-min-price {
  font-weight: bold;
  font-size: 16px;
}

.ends-time {
  white-space: nowrap;
  font-size: 14px !important;
}

.ends-time img {
  margin-right: 4px;
}

.search-time__char {
  justify-content: center;
  align-items: center;
  width: 11px;
}

.search-time i {
  font-style: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 11px;
}

.search-time i:nth-last-child(3) {
  width: 8px;
}

.search-min-price::before {
  margin-right: 5px;
  font-weight: normal;
  color: var(--black100);
}

.search-min-price--mobile {
  display: none !important;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  margin: 0;
}

.search-warning {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
}

.search-warning-text {
  color: var(--white-color);
  display: block;
  font-weight: bold;
  margin: 5px 0;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.95;
  letter-spacing: normal;
  text-align: center;
}

.search-alerts__button-icon--active {
  cursor: pointer !important;
}

.search-alerts__button-icon--inactive {
  cursor: default !important;
}

.search-alerts__icon {
  color: var(--primary-color);
}

.place-bid-modal__bids-list-title {
  color: var(--green600);
}

.place-bid-modal__arrow-icon {
  width: 5px;
  height: 8px;
  margin-right: 5px;
  margin-left: 10px;
}

.place-bid-modal .modal-footer {
  border-top: none;
  justify-content: center;
  padding: 10px 0;
}

.search-status-wrapper--mobile {
  width: 100%;
  margin: -10px 0 0 10px;
  display: flex;
  justify-content: flex-start;
}

.search-highest-bid {
  margin-top: -8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.search-highest-bid__status {
  white-space: nowrap;
}
.search-aucion-type__name {
  white-space: nowrap;
}

.auction-item__status-type {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.auction-basic-data-row {
  padding: 14px;
  flex-wrap: nowrap;
  flex: 1;
}

.auction-basic-data-row .main-description-container__basic-data {
  margin-top: auto;
  max-height: 40px;
}

.auction-basic-data__type-label {
  background-color: #2f5188;
  padding: 1px 6px;
  color: white;
  font-size: 0.8rem;
}

.auction-basic-data__code {
  background-color: #f2f4f7;
  padding: 2px 8px 2px 8px;
  color: #98a2b3;
}

@media (max-width: 580px) {
  .auction-basic-data-wrapper {
    margin-top: 25px;
    padding-bottom: 0;
  }
  .search-content-right--auction-item {
    margin-bottom: 45px;
  }
}

@media (max-width: 767px) {
  .search-time {
    font-size: var(--font-size-l);
    top: 3px;
    right: 10px;
  }
  .search-min-price {
    font-size: var(--font-size-l);
    margin: 0 0 0 5px;
  }
  .search-box-of-car--mobile {
    min-height: 225px;
    height: auto;
  }
  .search-box-of-img--mobile {
    max-height: 160px;
  }
  .auction-basic-data-row {
    min-height: 280px;
  }
  .auction-basic-data-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 10px;
  }
  .auction-basic-data-wrapper div p {
    margin-bottom: 3px;
  }
  .search-box-of-img--mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .search-box-of-img-wrapper,
  .search-box-of-img--mobile .search-box-of-img-wrapper .search-img-in-box {
    border-top-left-radius: 10px;
  }
  .search-content-left,
  .search-content-right {
    position: static;
    padding: 0;
  }
  .search-content-left {
    display: flex;
    flex-direction: column;
  }

  .search-title {
    display: none;
  }
  .search-title--mobile {
    display: block;
    font-size: var(--font-size-l);
    font-weight: bold;
    margin: 5px 0 5px 5px;
    text-align: center;
    text-transform: uppercase;
  }
  .search-title-wrapper--mobile {
    display: block;
  }
  .search-min-price--desktop {
    margin-bottom: 0;
  }
  .search-content-right {
    padding-right: 10px;
  }
  .search-highest-bid {
    margin-bottom: 0;
  }
}

.auction-basic-data-wrapper {
  align-items: center;
  flex: 1;
}

.auction-basic-data__container {
  row-gap: 2px;
}

.auction-basic-data-wrapper .basic-data-col {
  height: fit-content;
  font-weight: 600;
  font-size: 0.875rem;
}

.search-header__status-dropdown {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.search-header__status-dropdown-toggle-button,
.search-header__status-dropdown-toggle-button:hover {
  text-transform: lowercase;
  padding: 0 !important;
  background-color: transparent !important;
  color: var(--black100) !important;
}

.search-header__status-dropdown-icon {
  margin-left: 5px;
}

.dropdown.show .dropdown-menu[x-placement='bottom-start'],
.dropup.show .dropdown-menu[x-placement='bottom-start'],
.bootstrap-select.show .dropdown-menu[x-placement='bottom-start'] {
  width: 250px;
  top: -30px !important;
  right: 0 !important;
  left: initial !important;
}

.search-header__status-dropdown-menu-item:hover,
.search-header__status-dropdown-menu-item.active {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}

@media (min-width: 767px) {
  .search-title-wrapper--mobile {
    display: none;
  }
  .search-title--mobile {
    display: none;
  }
  .search-min-price--desktop {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;
  }
  .search-min-price--mobile {
    display: none;
  }
  .search-status-wrapper--mobile {
    display: none;
  }
}

.account-layout__nav-item.search-nav-item.active,
.account-layout__nav-item.search-nav-item:active,
.account-layout__nav-item.search-nav-item:hover {
  background-color: white;
}

.account-layout__nav-item.search-nav-item {
  padding-left: 24px !important;
}

.search-nav-item img {
  width: 32px;
}
