.analytics-content {
  background: white;
}

.analytics-pie-chart path.recharts-sector {
  cursor: pointer;
}

.analytics-content__segment {
  margin: 20px;
  padding: 40px;
  border: 1px solid var(--gray100);
}

.analytics-pie-chart__legend {
  gap: 15px;
}

.analytics-pie-chart__centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.analytics-pie-chart__legend-label .legend-label__color {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.analytics-pie-chart__empty {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  border: 60px solid var(--gray100);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
