*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-transform: initial !important;
  padding: 0 !important;
}

a:hover {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  background: none;
  border: none;
  outline: none !important;
}

td {
  border: none !important;
}

td:first-child,
td:last-child {
  padding-left: 20px !important;
}

td.rdtDay {
  padding-left: 0px !important;
}

td.rdtMonth {
  padding-left: 0px !important;
}

td.rdtYear {
  padding-left: 0px !important;
}

input[type='file'] {
  display: none;
}

/* REACT STRAP CLASS NAMES */

.table-responsive {
  overflow: hidden;
}

.btn {
  min-width: 100px;
  padding: 10px !important;
  border-radius: 20px;
  background-color: var(--primary-color) !important;
}

.btn-link,
.btn-link:hover,
.btn-link:active,
.btn-link:focus {
  color: var(--primary-color) !important;
  background-color: var(--white-color) !important;
}

.btn-info,
.btn-info:hover,
.btn-info:active,
.btn-info:focus {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
  box-shadow: none !important;
}

img {
  border-radius: 0;
}
